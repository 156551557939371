import { MEANS_ERROR, MEANS_SUCCESS } from "@ing-web/authentication";
import { LitElement } from 'lit';
import { TransferAccessTokenService } from "./transfer-access-token.service";
import { AMWA_TP_CLIENT_ID, ANONYMOUS_AUTHENTICATION_CONTEXT } from "../../util/service-utils";
import { initAnonymousSession } from "@ing-web/token-manager";

export const AUTHENTICATE_WITH_OTP_EVENT = "authenticate-with-otp-event";

export const OTP_AUTHENTICATION_CONTEXT = {
    clientId: AMWA_TP_CLIENT_ID,
    identifyeeType: "customer",
    requiredLevelOfAssurance: 3,
    scopes: ["personal-data"],
};

export class BmgCrontoOtpMeans extends LitElement {
  static get is() {
    return 'bmg-cronto-otp-means';
  }

  constructor() {
    super();
    this._boundHandleAuthenticateWithOtp = this._handleAuthenticateWithOtp.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(AUTHENTICATE_WITH_OTP_EVENT, this._boundHandleAuthenticateWithOtp);
  }

  disconnectedCallback() {
    window.removeEventListener(AUTHENTICATE_WITH_OTP_EVENT, this._boundHandleAuthenticateWithOtp);
    super.disconnectedCallback();
  }

  _handleAuthenticateWithOtp = ({ detail: { data: transferOtpResponse } }) =>
      this._authenticateWithTransferOtp( transferOtpResponse );

  _dispatchMeansEvent(type, detail) {
    const event = new CustomEvent(type, { detail });
    this.dispatchEvent(event);
  }

  _getTransferAccessToken = (transferOtpResponse) =>
      TransferAccessTokenService.getTransferAccessToken(transferOtpResponse, OTP_AUTHENTICATION_CONTEXT);

  _authenticateWithTransferOtp = (transferOtpResponse) =>
      initAnonymousSession( ANONYMOUS_AUTHENTICATION_CONTEXT )
          .then( _ => this._getTransferAccessToken( transferOtpResponse ) )
          .then( response => ({ authenticationResult: response.data }) )
          .then( eventDetail => this._dispatchMeansEvent(MEANS_SUCCESS, eventDetail) )
          .catch( error => this._dispatchMeansEvent( MEANS_ERROR, error ) );
}

customElements.define(BmgCrontoOtpMeans.is, BmgCrontoOtpMeans);