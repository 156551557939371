import { amwaApplicationStyle } from "../../../../amwa-application-style";
import { css, unsafeCSS } from 'lit';
import { bmgBlue, bmgBlue18, bmgWhite60, fontRegular, fontSizeMl } from "@bmg-web/bmg-styles";

export const styles = css`
  ${ amwaApplicationStyle }

  .form-container {
    background-color: ${unsafeCSS(bmgBlue18)};
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
  }

  .step-title {
    ${ unsafeCSS(fontSizeMl) }
    ${ unsafeCSS(fontRegular) }
    color: ${ unsafeCSS(bmgBlue) };
  }

  .spinner-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    opacity: 0.5;
    background-color: ${unsafeCSS(bmgWhite60)};
    z-index: 999;
  }

  #bmg-spinner {
    position: fixed;
    width: 100px;
    height: 100px;
  }
`;
