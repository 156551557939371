import { css, html, LitElement } from 'lit';
import '@bmg-web/bmg-card';
import '@bmg-web/bmg-ripple';
import './amwa-login-link';
import './amwa-need-help-link';
import { goToMobileLogon, goToPasswordLogon, goToScannerLogon, goToTokenLogon } from '../util/navigate';
import { amwaApplicationStyle } from '../amwa-application-style';

export class AmwaLoginChoice extends LitElement {

    static get properties() {
        return {};
    }

    constructor() {
        super();
    }

    static get styles() {
        return [
            amwaApplicationStyle,
            css`
            `,
        ];
    }

    render() {
        return html`

            <bmg-card>
                <div class="headline">Log on with</div>
                <amwa-login-link @click="${goToMobileLogon}">Mobile</amwa-login-link>
                <amwa-login-link @click="${goToScannerLogon}">Scanner</amwa-login-link>
                <amwa-login-link @click="${goToTokenLogon}">Token</amwa-login-link>
                <amwa-login-link @click="${goToPasswordLogon}">Password</amwa-login-link>
                <amwa-need-help-link .href="${'mailto:administration@mendesgans.nl'}">
                    Contact us
                </amwa-need-help-link>
            </bmg-card>
        `;
    }
}

customElements.define('amwa-login-choice', AmwaLoginChoice);
