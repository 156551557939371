import { html } from 'lit';
import { styles } from "./BmgFeatScannerIdentifyYourself.style";
import '@bmg-web/bmg-ripple';
import "@bmg-web/bmg-form";
import "@bmg-web/bmg-button";
import { BmgFeatScannerElementBase, SUCCESS } from "../../../components/base/BmgFeatScannerElementBase";
import { activationHelpDialogModel } from "../../../model/help-dialog-model";
import "../../../../components/amwa-login-link";
import "../../../../components/amwa-need-help-link";
import { ScannerService } from "../../../services/scanner.service";
import {
    AUTHENTICATE_WITH_OTP_EVENT,
    OTP_AUTHENTICATION_CONTEXT
} from "../../../../components/authentication/bmg-cronto-otp-means";
import { MEANS_CLASS_MAP, MEANS_COMPONENTS } from "../../BmgFeatScannerActivation";
import { BmgFeatScannerLoginWithOtp } from "../otp-2-login-with-otp/BmgFeatScannerLoginWithOtp";

export class BmgFeatScannerIdentifyYourself extends BmgFeatScannerElementBase {
    static get is() {
        return 'bmg-feat-scanner-identify-with-choice';
    }

    static get styles() {
        return styles;
    }

    static get properties() {
        return {
            userId: {
                type: String,
                attribute: "user-id",
            },
            _otpErrorMessage: {
                type: String,
                state: true,
            },
            _authenticationInProgress: {
                type: Boolean,
                state: true,
            }
        };
    }

    connectedCallback() {
        super.connectedCallback();
        if (this.userId) { // when user-id is provided, it's logged on (with a token)
            this._authenticationInProgress = true;
            // TODO:#5341252: implement keep-alive for the Token session, we need it until the end of activation flow. Check BMG Token Manager used by Megabank apps..
            this._performAuthenticationWithToken( this.userId );
        }
    }

    _performAuthenticationWithToken = (userName) =>
        ScannerService.authenticateWithToken({ userName })
            .then(transferOtpResponse => this.fireCustomEvent(AUTHENTICATE_WITH_OTP_EVENT, transferOtpResponse))
            .catch(error => this._handleAuthenticateWithTokenError(error));

    _handleAuthenticateWithTokenError = (error) => {
        console.error("Error getting Access Token", error);
        this._authenticationInProgress = false;
        this.handleError(BmgFeatScannerIdentifyYourself.is, error);
    }

    _handleAuthenticationSuccess() {
        this.dispatchEvent(new CustomEvent(BmgFeatScannerLoginWithOtp.is + '-success', {
            bubbles: true,
            composed: true,
            detail: {}
        }));
    }

    _handleMeansSelection = ( selectedMeans ) => {
        this.fireCustomEvent(BmgFeatScannerIdentifyYourself.is + SUCCESS, { selectedMeans });
    }

    get _helpDialogElement() {
        return this.shadowRoot.querySelector('#helpDialog') || {};
    }

    _openHelpDialog() {
        this._helpDialogElement.open();
    }

    _renderContent() {
        return html`
            <div class="step-title">1. Identify yourself</div>
            <bmg-form id="bmgForm" name="form">
                <div class="step-note-text">
                    If you have your token at hand, that's the quickest way.<br/>
                    If not, we'll send you a one-time password via email.
                </div>
                <div class="means-choice-container">
                    <amwa-login-link @click="${() => this._handleMeansSelection('Token')}">
                        Via token
                    </amwa-login-link>
                    <amwa-login-link @click="${() => this._handleMeansSelection('OTP')}">
                        Via one-time password
                    </amwa-login-link>
                </div>
                <div class="button-group-container">
                    <bmg-button class="right" flat inverted @click=${this._openHelpDialog}>Need help?</bmg-button>
                    <bmg-feat-scanner-help-dialog id="helpDialog"
                                                  .model=${activationHelpDialogModel}></bmg-feat-scanner-help-dialog>
                </div>
            </bmg-form>
        `;
    }

    render() {
        return html`
            <div class="headline">Activate your scanner</div>
            
            ${ this._authenticationInProgress ? this._renderSpinnerOverlay() : this._renderContent() }
            
            <amwa-hidden-feat-authentication
              id="feat-authentication"
              .context=${OTP_AUTHENTICATION_CONTEXT}
              .meansClassMap=${MEANS_CLASS_MAP}
              .withMeans=${MEANS_COMPONENTS}
              @authentication-success-event=${this._handleAuthenticationSuccess}
              @authentication-means-error=${this._handleAuthenticateWithTokenError}
              @authentication-conf-error=${this._handleAuthenticateWithTokenError}
            ></amwa-hidden-feat-authentication>
        `;
    }
}
