import { ajaxApi } from 'ing-web/ajax';
import { axiosInterceptor } from "@ing-web/token-manager";
import { LogTraceInterceptor } from "./log-trace-interceptor";
import { apiGatewayAjaxOptions } from "../util/service-utils";
import { deleteCookie, isCookiePresent } from "../util/cookie-service";
import { APP_SETTINGS_INITIALIZED_EVENT, AppSettings } from "../util/app-settings";
import { PrefillInterceptor } from "./prefill-interceptor";


const BMG_INIT_COMPLETED_MARKER = 'bmg-init-completed';

const getApiGatewayAjax = () => {
    const apiGatewayAjax = ajaxApi;

    if (!apiGatewayAjax.hasOwnProperty( BMG_INIT_COMPLETED_MARKER )) {

        const handleAppSettingsInitialized = () => {
            // Inject Ajax options for API Gateway calls
            apiGatewayAjax.options = {
                ...apiGatewayAjax.options,
                ...apiGatewayAjaxOptions(),
            };
            // Set up pre-filling of the response codes (only configured in non-PROD)
            const prefillConfig = AppSettings.prefillConfig;
            if (!!prefillConfig) {
                PrefillInterceptor.setConfig( prefillConfig );
                apiGatewayAjax.responseInterceptors.push( PrefillInterceptor.prefillInterceptor );
            }
        }
        window.addEventListener(APP_SETTINGS_INITIALIZED_EVENT, handleAppSettingsInitialized, { once: true });

        // Add Token Manager's axiosInterceptor to inject TouchPoint Access Token
        apiGatewayAjax.requestInterceptors.push( axiosInterceptor );

        // Log successful call events for dashboard
        apiGatewayAjax.responseInterceptors.push( LogTraceInterceptor.traceInterceptor );
        // Log TouchPoint traceId on errors
        apiGatewayAjax.responseErrorInterceptors.push( LogTraceInterceptor.errorInterceptor );

        apiGatewayAjax[BMG_INIT_COMPLETED_MARKER] = true;
    }

    return apiGatewayAjax;
}

export const apiGatewayAjax = getApiGatewayAjax();

// we need to make a GET call to initialize XSRF-TOKEN cookie for consequent POST requests
// currently there's no valid GET endpoint we can call, so just call the root (returns error, but with the cookie)
export const initializeXsrfToken = async () => {
    // AMWA and API Gateway both set XSRF-TOKEN cookie (though on different paths), which might lead to a conflict later.
    // To avoid the conflict, clear the AMWA's copy, as it seems not to be required.
    deleteCookie('XSRF-TOKEN', '/bmg-access-means');

    // check if the cookie was already set
    if (isCookiePresent('XSRF-TOKEN')) {
        return;
    }

    // TODO: this is not setting XSRF-TOKEN cookie anymore :( at least on TST1. Maybe delete it..
    await apiGatewayAjax.get('/', {
        validateStatus: (status) => true // do not throw errors
    });

    if (!isCookiePresent('XSRF-TOKEN')) {
        const apiGatewayDomain = "." + location.hostname;
        // TODO: consider adding domain param to setCookie() and use it for this line
        document.cookie = `XSRF-TOKEN=${ generateUUID() }; path=/; domain=${ apiGatewayDomain };`
    }
}

const generateUUID = () => crypto?.randomUUID?.() || fallbackGenerateUUID();

// crypto.randomUUID is only available in secure context and in the later browsers, for other cases this (less secure) fallback
// source: https://stackoverflow.com/a/8809472
function fallbackGenerateUUID() { // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16; // random number between 0 and 16
        if(d > 0){ // Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else { // Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}