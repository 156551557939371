import { css, html, LitElement } from 'lit';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import '@bmg-web/bmg-card';
import { AmwaBackToLoginLink } from '../components/amwa-back-to-login-link';
import { amwaApplicationStyle } from '../amwa-application-style';
import { BmgFeatScannerActivation } from "../bmg-feat-scanner/activation/BmgFeatScannerActivation";
import { Psd2InactivityWarning } from "../components/authentication/psd2-inactivity-warning/Psd2InactivityWarning";
import { initializeXsrfToken } from "../ajax/api-gateway-ajax";
import { deleteLogonTypeCookie } from "../util/cookie-service";
import { BmgFeatScannerTechnicalError } from "../bmg-feat-scanner/components/error/BmgFeatScannerTechnicalError";


export const SHOW_AMWA_BACK_TO_LOGIN_LINK = "show-amwa-back-to-login-link";

class ActivateScannerPage extends ScopedElementsMixin(LitElement) {

  static get scopedElements() {
    return {
      "amwa-back-to-login-link": AmwaBackToLoginLink,
      "bmg-feat-scanner-activation": BmgFeatScannerActivation,
      "psd2-inactivity-warning": Psd2InactivityWarning,
      "bmg-feat-scanner-technical-error": BmgFeatScannerTechnicalError,
    };
  }

  static get properties() {
    return {
      _showAmwaBackToLoginLink: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this._showAmwaBackToLoginLink = true;
    this.boundShowAmwaBackToLoginLinkHandler = this._handleShowAmwaBackToLoginLink.bind(this);
  }

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener(SHOW_AMWA_BACK_TO_LOGIN_LINK, this.boundShowAmwaBackToLoginLinkHandler);
    deleteLogonTypeCookie();
    await initializeXsrfToken();
  }

  disconnectedCallback() {
    window.removeEventListener(SHOW_AMWA_BACK_TO_LOGIN_LINK, this.boundShowAmwaBackToLoginLinkHandler);
    super.disconnectedCallback();
  }

  static get styles() {
    return [
      amwaApplicationStyle,
      css`
          .card-body {
            border-bottom: 2px;
            margin-bottom: 16px
          }

          .scanner-activation-card {
              width: 470px;
          }
          `,
    ];
  }

  _handleShowAmwaBackToLoginLink(event) {
    this._showAmwaBackToLoginLink = event.detail.showAmwaBackToLoginLink;
  }

  render() {
    return html`
        <div class="scanner-activation-card">
            <bmg-feat-scanner-technical-error></bmg-feat-scanner-technical-error>
        </div>
        <bmg-card>
         <div class="card-body">
           <bmg-feat-scanner-activation
               aria-live="polite"
           ></bmg-feat-scanner-activation>
         </div>
          ${this._showAmwaBackToLoginLink
              ? html`<amwa-back-to-login-link></amwa-back-to-login-link>`
              : html``
            }
          <psd2-inactivity-warning></psd2-inactivity-warning>
        </bmg-card>
    `;
  }
}

customElements.define('activate-scanner-page', ActivateScannerPage);
