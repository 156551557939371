export class CrontoType {
    static SERIAL_NUMBER = 'serialNumber';
    static ENROL_DEVICE = 'enrolDevice';
    static ACTIVATE_DEVICE = 'activateDevice';
    static AUTHENTICATION = 'authentication';
    static AUTHENTICATION_STANDALONE = 'authenticationStandalone';
}

export class ScannerStatus {
    static OFFERED = 'OFFRD_AR';
    static REQUESTED = 'REQD_AR';
    static POTENTIAL = 'POTEN_AR';
    static EFFECTIVE = 'EFF_AR';
    static COMPLETED = 'COMPLD_AR';
}

export const crontoTypes = {
    serialNumber: {
        token: '/security-means/cronto-hardware-token/actions/management/enrolment/identification',
    },
    enrolDevice: {
        token: '/security-means/cronto-hardware-token/actions/management/enrolment/initiate',
        response: '/security-means/cronto-hardware-token/actions/management/enrolment/validate',
    },
    activateDevice: {
        token: '/security-means/cronto-hardware-token/actions/management/activation/initiate',
        response: '/security-means/cronto-hardware-token/actions/management/activation/validate',
    },
    authentication: {
        token: '/security-means/cronto-hardware-token/actions/authentication/initiate',
        response: '/security-means/cronto-hardware-token/actions/authentication/authenticate',
    },
    authenticationStandalone: {
        token: '/security-means/cronto-hardware-token/actions/authentication/standalone/initiate',
        response: '/security-means/cronto-hardware-token/actions/authentication/standalone/authenticate',
    },
    meansStatus: '/security-means/cronto-hardware-token/actions/management/agreements/latest'
};

export const ENCRYPTED_STATE_HEADER_KEY = 'x-ing-crontoencryptedstate'; // TODO: should be handled by interceptor. see story #4552695