import { html, LitElement } from 'lit';
import '@bmg-web/bmg-card';
import '@bmg-web/bmg-ripple';
import './amwa-login-link';
import './amwa-need-help-link';
import {goToMobileLinkIbUser, goToTokenLinkIbUser} from '../util/navigate';
import { amwaApplicationStyle } from '../amwa-application-style';

export class AmwaLinkIbUserChoice extends LitElement {

    static get styles() {
        return [
            amwaApplicationStyle,
        ];
    }

    render() {
        return html`
            <bmg-card>
                <div id="link-ib-user-title" class="headline">Link user</div>
                <amwa-login-link id="link-ib-user-token" @click="${goToTokenLinkIbUser}">Token</amwa-login-link>
                <amwa-login-link id="link-ib-user-mobile" @click="${goToMobileLinkIbUser}">Mobile</amwa-login-link>
                <amwa-need-help-link href="mailto:administration@mendesgans.nl">
                    Contact us
                </amwa-need-help-link>
            </bmg-card>
        `;
    }
}

customElements.define('amwa-link-ib-user-choice', AmwaLinkIbUserChoice);
