import { css, html, LitElement } from 'lit';
import '@bmg-web/bmg-ripple';
import { amwaApplicationStyle } from '../amwa-application-style';
import '@bmg-web/bmg-icon';
import { goToScannerActivation } from "../util/navigate";


export class AmwaScannerActivationLink extends LitElement {
    static get is() {
        return 'amwa-scanner-activation-link';
    }

    static get properties() {
        return {};
    }

    static get styles() {
        return [amwaApplicationStyle,
            css`
            `,
        ];
    }

    _goToScannerActivation(event) {
        event.preventDefault();
        goToScannerActivation();
        return false;
    }

    render() {
        return html`
            <div class="chevron-link">
                <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                <a href="#" @mousedown=${ this._goToScannerActivation }>New scanner? Activate it here first!</a>
            </div>
        `;
    }
}

customElements.define('amwa-scanner-activation-link', AmwaScannerActivationLink);
