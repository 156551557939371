import { bmgAjax } from "@bmg-web/bmg-ajax";
import { bmgAjaxOptions } from "../../util/service-utils";


export class LogService {

    static trace = ({ operation, context }) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/log/trace', { operation, context }, {
            ...bmgAjaxOptions()
        });

    static traceError = ({ operation, context }) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/log/trace-error', { operation, context }, {
            ...bmgAjaxOptions()
        });

}