import { BmgInput } from "@bmg-web/bmg-input";


export class AmwaInput extends BmgInput {

  firstUpdated() {
    super.firstUpdated();

    if (!!this.autofocus) {
      // BmgInput's implementation of `autofocus` doesn't work for single-page apps like AMWA,
      // delaying setting the focus fixes it
      setTimeout(() => this.inputElement.focus(), 0);
    }
  }

  onFocusIn(e) {
    // BmgInput's implementation of `onFocusIn` is hardcoded to `BMG-INPUT` tag name, but we use our own custom tags,
    // so need to explicitly execute focusing logic.
    this.inputElement.focus();
    this.dispatchEvent(new Event('focus', e));
    super.onFocusIn(e);
  }

}