import { amwaApplicationStyle } from "../../../amwa-application-style";
import { css, unsafeCSS } from 'lit';
import { bmgBlue, fontRegular, fontSizeMl } from "@bmg-web/bmg-styles";

export const styles = css`
  ${ amwaApplicationStyle }

  .form-container {
    padding: 16px 10px;
  }

  .step-title {
    ${ unsafeCSS(fontSizeMl) }
    ${ unsafeCSS(fontRegular) }
    color: ${ unsafeCSS(bmgBlue) };
  }

  .button {
    margin-bottom: 16px;
  }

  .stepper {
    display: inline-block;
    margin-bottom: 10px;
  }

  .step {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .circle {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: #4285f4;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .line {
    width: 2px;
    height: 20px;
  }

  .step .circle {
    background-color: rgb(16, 122, 193);
  }

  .stepname {
    margin-left: 17px;
    ${ unsafeCSS(fontSizeMl) }
    ${ unsafeCSS(fontRegular) }
  }

  .sideLineInitial {
    border: 1px solid rgb(216, 216, 216);
    margin-left: 3.5px;
    float: left;
    padding: 0;
    min-height: 17px;
  }

  .button-white {
    background-color: #000066;
  }
  
`;
