import IMask from "imask/esm/imask";
import "imask/esm/masked/pattern";
import { AmwaInput } from "./AmwaInput";


export class AmwaMaskedInput extends AmwaInput {

  static get properties() {
    return {
      ...super.properties,
      /** Pattern removed, use mask instead. Use {@link AmwaInput} directly if mask is unnecessary. */
      pattern: {
        type: String,
        attribute: false,
      },
      /**
       * Pattern mask representing required input format.
       * For syntax, see {@link https://imask.js.org/guide.html#masked-pattern|IMask docs}.
       * */
      mask: {
        type: String,
      },
      /**
       * Value without mask formatting, by default removes i.a. spaces and dashes. For more control, adjust the mask.
       */
      unmaskedValue: {
        type: String,
        attribute: false,
      },
      _internalMask: {
        type: Object,
        attribute: false,
      }
    };
  }

  get unmaskedValue() {
    return this._internalMask.unmaskedValue;
  }

  firstUpdated() {
    super.firstUpdated();
    if (!!this.mask) {
      this._internalMask = IMask(this.inputElement, {
        mask: this.mask,
      });
    }
  }

}