const COOKIE_KEY = {
    TRUSTED_DEVICE_ID: 'tdid',
    REMEMBER_USER_ID: 'bmg-scanner-user-id',
    LOGON_TYPE: 'logonType',
};

const DEFAULT_PATH = "/bmg-access-means";
const DEFAULT_DAYS_UNTIL_EXPIRATION = 75;

const isCookiePresent = (key) => !!getCookieValue(key);

const getCookieValue = (key) => {
    const match = decodeURIComponent(document.cookie).match(`(^|;)\\s*${key}\\s*=\\s*([^;]*)`);
    if (!match) {
        return null;
    }
    return match[2];
};

const setCookie = (key, value, path = DEFAULT_PATH) => {
    document.cookie = `${key}=${value}; path=${path}; expires=${_getDefaultExpirationDate()};`;
}

const deleteCookie = (key, path) => document.cookie = `${key}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;

const _getDefaultExpirationDate = () => {
    const DAYS_TO_MS_FACTOR = 24 * 60 * 60 * 1000;
    const now = new Date();
    const expirationDate = new Date(now.getTime() + (DEFAULT_DAYS_UNTIL_EXPIRATION * DAYS_TO_MS_FACTOR));
    return expirationDate.toUTCString();
}

const deleteLogonTypeCookie = () => {
    deleteCookie(COOKIE_KEY.LOGON_TYPE, DEFAULT_PATH);
}
export { isCookiePresent, getCookieValue, setCookie, deleteCookie, deleteLogonTypeCookie, COOKIE_KEY };