import { bmgAjax } from "@bmg-web/bmg-ajax";
import { bmgAjaxOptions } from "../../util/service-utils";


export class SessionService {

    static triggerUnauthenticatedSessionCookie = () =>
        bmgAjax.post('/bmg-access-means/v1/public/session/anonymous/initiate', null, {
            ...bmgAjaxOptions(),
            withCredentials: false,
        });

    static safeLogoutSsoSession = () => {
        return bmgAjax.post('/bmg-access-means/v1/private/logout', null, {
            ...bmgAjaxOptions(),
            validateStatus: (status) => true // do not throw errors
        });
    }

}
