import { html } from 'lit';
import { BmgButton } from "@bmg-web/bmg-button/lib/BmgButton";
import "../../../components/help-dialog/bmg-feat-scanner-help-dialog.js";
import { styles } from "./BmgFeatScannerAlreadyActivated.style";
import { BmgFeatScannerElementBase } from "../../../components/base/BmgFeatScannerElementBase";
import { activationHelpDialogModel } from "../../../model/help-dialog-model";
import { AmwaBackToLoginLink } from "../../../../components/amwa-back-to-login-link";

export const SCANNER_ALREADY_ACTIVATED_EVENT = 'bmg-feat-scanner-already-activated';

export class BmgFeatScannerAlreadyActivated extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-already-activated';
  }

  static get scopedElements() {
    return {
      "bmg-button": BmgButton,
      "amwa-back-to-login-link": AmwaBackToLoginLink,
    };
  }

  static get properties() {
    return {};
  }

  constructor() {
    super();
  }


  static get styles() {
    return styles;
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">You already have an active scanner</div>
        <div class="form-container">
          <bmg-alert id="alert-scanner-already-activated" type="warning">
            According to our administration, you already have
            an active scanner. You can only have one active
            scanner at a time. This means you cannot
            continue this activation.
          </bmg-alert>
          <p>
            <div class="link-paragraph">Lost your scanner and in need of new one?</div>
            Report the incident to your coordinator and to your BMG
            serviceteam via
            <a class="mail-link" href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.
          </p>
          
          <p>
          <div class="link-paragraph">Not sure which scanner is yours?</div>
            Then maybe that was the reason you tried to activate a new
            one. If so, please find out which scanner is yours. Ask your
            coordinator or BMG service team to check in Megabank
            which serial number belongs to your account. Please
            contact your coordinator or BMG service team via
            <a class="mail-link" href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.
          </p>
          <bmg-button class="right" flat inverted @click=${this._openHelpDialog}>Need help?</bmg-button>
          <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
        </div>
    `;
  }
}