import { html } from 'lit';
import { BmgButton } from "@bmg-web/bmg-button/lib/BmgButton";
import { BmgForm } from "@bmg-web/bmg-form/lib/BmgForm";
import "../../../components/help-dialog/bmg-feat-scanner-help-dialog.js";
import { AmwaBackToLoginLink } from '../../../../components/amwa-back-to-login-link';
import { AmwaNeedHelpLink } from '../../../../components/amwa-need-help-link';
import { AmwaHiddenFeatAuthentication } from "../../../../components/authentication/amwa-hidden-feat-authentication";
import {
  AUTHENTICATE_WITH_OTP_EVENT,
  OTP_AUTHENTICATION_CONTEXT
} from "../../../../components/authentication/bmg-cronto-otp-means";
import { styles } from "./BmgFeatScannerLoginWithOtp.style";
import { MEANS_CLASS_MAP, MEANS_COMPONENTS } from "../../BmgFeatScannerActivation";
import { BmgFeatScannerElementBase } from "../../../components/base/BmgFeatScannerElementBase";
import { ScannerService } from "../../../services/scanner.service";
import { activationHelpDialogModel } from "../../../model/help-dialog-model";
import { AmwaInput } from "../../../../components/amwa-input/AmwaInput";
import { CheckDigitValidationService } from "../../../services/check-digit-validation-service";
import { initializeXsrfToken } from "../../../../ajax/api-gateway-ajax";


const OTP_PATTERN = /^\d{10}$/ // 10 digits

const OTP_INCORRECT_FORMAT_ERROR_MESSAGE  = "The format is not correct. Please enter a 10 digit code.";
const OTP_INVALID_ERROR_MESSAGE = "The registration code is not valid. Please check and try again.";


export class BmgFeatScannerLoginWithOtp extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-login-with-otp';
  }

  static get scopedElements() {
    return {
      "bmg-form": BmgForm,
      "amwa-input": AmwaInput,
      "bmg-button": BmgButton,
      "amwa-back-to-login-link": AmwaBackToLoginLink,
      "amwa-need-help-link": AmwaNeedHelpLink,
      "amwa-hidden-feat-authentication": AmwaHiddenFeatAuthentication,
    };
  }

  static get properties() {
    return {
      userId: {
        type: String,
        attribute: "user-id"
      },
      anonymizedEmail: {
        type: String,
        attribute: "anonymized-email"
      },
      _otpErrorMessage: {
        type: String,
        attribute: false,
        state: true
      },
      _activationProgress: {
        type: Boolean,
        attribute: false
      }
    };
  }

  constructor() {
    super();
    this._otpErrorMessage = OTP_INCORRECT_FORMAT_ERROR_MESSAGE;
    this._activationProgress = false;
  }

  async connectedCallback() {
    super.connectedCallback();
    await initializeXsrfToken();
  }

  static get styles() {
    return styles;
  }

  get _otpElement() {
    return this.shadowRoot.querySelector('#inputOtp') || {};
  }

  _performAuthenticationWithOtp(userName, otp) {
    const otpAuthenticateRequest = { userName , otp };

    ScannerService.authenticateWithOtp(otpAuthenticateRequest)
        .then(transferOtpResponse => this.fireCustomEvent(AUTHENTICATE_WITH_OTP_EVENT, transferOtpResponse))
        .catch(error => this._handleAuthenticateWithOtpError(error));
  }

  _handleAuthenticateWithOtpError(error) {
    this._activationProgress = false;

    if (error?.response?.status === 400) {
      this._otpErrorMessage = OTP_INVALID_ERROR_MESSAGE;
      this._otpElement.invalid = true;
    } else {
      this.handleError(this.is, error);
    }
  }

  _handleNext() {
    this._clearTechnicalError();
    const userId = this.userId;
    const otpElement = this._otpElement;
    const isOtpValid = otpElement.validate();
    if (isOtpValid) {
      this._activationProgress = true;
      this._performAuthenticationWithOtp(userId, otpElement.value);
    } else {
      console.error("Validation error");
    }
  }

  _validateOtp(value) {
    console.log("_validateOtp", value); // TODO: remove
    const isValidOtpFormat = OTP_PATTERN.test(value);
    if (!isValidOtpFormat) {
      this._otpErrorMessage = OTP_INCORRECT_FORMAT_ERROR_MESSAGE;
      return false;
    }
    if (!CheckDigitValidationService.validateOneTimePasswordCheckDigit(value)) {
      this._otpErrorMessage = OTP_INVALID_ERROR_MESSAGE;
      return false;
    }
    return true;
  }

  // This will be called after successful authentication
  _handleAuthenticationSuccess(event) {
    console.log("_handleAuthenticationSuccess", JSON.stringify(event)); // TODO: remove
    this.dispatchEvent(new CustomEvent(BmgFeatScannerLoginWithOtp.is + '-success', {
      bubbles: true,
      composed: true,
      detail: {}
    }));
  }

  // This will be called on an authentication error. TODO: Ensure handleError can correctly parse th
  _handleAuthenticationError(event) {
    console.log("_handleAuthenticationError", JSON.stringify(event)); // TODO: remove
    this.handleError(this.is, event);
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">Confirm your email</div>
        <ul class="info-bullets">
            <li>
                We have sent a registration code to ${this.anonymizedEmail}
            </li>
            <li>
                Enter the 10-digit registration code you have received by email, then click 'Next'.
                The code is valid for 10 minutes.
            </li>
        </ul>
        <bmg-form id="bmgForm" name="form">
            <div class="form-container">
                <amwa-input
                        id="inputOtp"
                        name="otp"
                        label="Registration code"
                        autofocus
                        required
                        minlength="10"
                        maxLength="10"
                        pattern="[0-9]"
                        .validator=${ (value) => this._validateOtp(value) }
                        .errorMessage=${ this._otpErrorMessage }
                ></amwa-input>
            </div>
            <bmg-button class="button" .disabled="${ this._activationProgress }" @click=${ this._handleNext }>Next</bmg-button>
            <bmg-button class="right" flat inverted @click=${ this._openHelpDialog }>Need help?</bmg-button>
            <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
        </bmg-form>

        <amwa-hidden-feat-authentication
                id="feat-authentication"
                .context=${ OTP_AUTHENTICATION_CONTEXT }
                .meansClassMap=${ MEANS_CLASS_MAP }
                .withMeans=${ MEANS_COMPONENTS }
                @authentication-success-event=${ this._handleAuthenticationSuccess }
                @authentication-means-error=${ this._handleAuthenticationError }
                @authentication-conf-error=${ this._handleAuthenticationError }
        >
        </amwa-hidden-feat-authentication>
    `;
  }
}