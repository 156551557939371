import { ScopedElementsMixin } from "@open-wc/scoped-elements";
import { html, LitElement } from "lit";


export const GO_BACK = '-go_back';
export const SUCCESS = '-success';
export const GENERIC_EXCEPTION_EVENT_ID = 'bmg-feat-scanner-generic-exception';
export const TECHNICAL_EXCEPTION_EVENT_ID = 'bmg-feat-scanner-technical-exception';
export const CLEAR_TECHNICAL_EXCEPTION_EVENT_ID = 'bmg-feat-scanner-clear-technical-exception';
export const INVALID_SCANNER_MEANS_AGREEMENT_ERROR_CODE = "invalid-scanner-means-agreement-error-code";
export const UNKNOWN_SELECTED_MEANS_AGREEMENT_ERROR_CODE = "unkown-selected-means-agreement-error-code";
const TECHNICAL_ERROR_CODES = new Set([ INVALID_SCANNER_MEANS_AGREEMENT_ERROR_CODE ]);

export class BmgFeatScannerElementBase extends ScopedElementsMixin(LitElement) {

    handleError = (origin, error) => {
        const code = error?.code || "Unknown";
        const status = error?.response?.status || "Unknown";
        const errorData = error?.response?.data || error?.message || "Unexpected error";
        const dateHeader = error?.response?.headers?.date || null;
        const isTechnicalError = error?.hasOwnProperty("request") || TECHNICAL_ERROR_CODES.has(code);
        if (code === 'ECONNABORTED' && errorData.includes('timeout')) {
            this.fireCustomEvent(TECHNICAL_EXCEPTION_EVENT_ID);
        } else if (isTechnicalError) {
            this.fireCustomEvent(TECHNICAL_EXCEPTION_EVENT_ID, { dateHeader });
        } else {
            this.fireCustomEvent(GENERIC_EXCEPTION_EVENT_ID, { origin, status, errorData });
        }
    }

    fireCustomEvent = (eventId, detail) => {
        console.log('fireCustomEvent:', eventId, detail); // TODO: remove
        const customEventInit = { bubbles: true, composed: true, detail };
        const customEvent = new CustomEvent(eventId, customEventInit);
        this.dispatchEvent(customEvent);
    }

    _clearTechnicalError() {
        this.fireCustomEvent(CLEAR_TECHNICAL_EXCEPTION_EVENT_ID);
    }

    _renderCrontoImageSpinner() {
        return html`
            <div class="cronto-container">
                <bmg-spinner id="spinner" active></bmg-spinner>
            </div>
        `;
    }

    _renderSpinnerOverlay() {
        return html`
            <div class="spinner-overlay">
                <bmg-spinner id="bmg-spinner" active="true">Loading...</bmg-spinner>
            </div>
        `;
    }

}