import { css, unsafeCSS } from 'lit';
import { scannerLogonStyle } from "../BmgFeatScannerLogon.style";
import { bmgBlack60, bmgBlue, fontMedium, fontSizeMd, fontSizeSm, } from "@bmg-web/bmg-styles";

export const styles = css`
  ${scannerLogonStyle}

  .bmg-scanner-icon {
    height: 190px;
    background-image: url("/bmg-access-means/v1/static/image/bmg-scanner.png");
    background-size: 62%;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
  }

  #inputUserId {
    margin-bottom: 16px;
  }
  
  .remember-me {
    ${ unsafeCSS(fontSizeMd) }
    ${ unsafeCSS(fontMedium) }
    color: ${ unsafeCSS(bmgBlack60) };
  }

  .remember-me-cookie {
    padding-left: 30px;
    display: block;
    ${ unsafeCSS(fontSizeSm) }
    ${ unsafeCSS(fontMedium) }
    color: ${ unsafeCSS(bmgBlack60) };
  }
  
  .remember-me-cookie a{
    color: ${ unsafeCSS(bmgBlue) };
  }

`;
