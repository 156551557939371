import { COOKIE_KEY, getCookieValue, setCookie } from "./cookie-service";
import "./ua-parser";
import "./fingerprint2";


const generateRandomTrustedDeviceId = () => {
    const SIZE_IN_BYTES = 12;
    const randomBytes = crypto.getRandomValues(new Uint8Array(SIZE_IN_BYTES));
    const randomBytesAsString = String.fromCharCode.apply(null, randomBytes);
    return btoa(randomBytesAsString);
}

let trustedDeviceCookieValue = getCookieValue(COOKIE_KEY.TRUSTED_DEVICE_ID);
if (!trustedDeviceCookieValue) {
    trustedDeviceCookieValue = generateRandomTrustedDeviceId();
    setCookie(COOKIE_KEY.TRUSTED_DEVICE_ID, trustedDeviceCookieValue);
}
const userAgentParser = new UAParser();
const browser = userAgentParser.getBrowser().name;
const operatingSystem = userAgentParser.getOS().name + " " + userAgentParser.getOS().version;
const _deviceMetadata = {
    browser: browser,
    operatingSystem: operatingSystem,
    deviceUniqueIdentifier: trustedDeviceCookieValue,
}

const options = {
    preprocessor: function (key, value) {
        if (key === 'userAgent') {
            return operatingSystem + ' ' + browser;
        } else {
            return value;
        }
    },
    excludes: {
        screenResolution: true,
        language: true,
        adBlock: true,
        audio: true,
        availableScreenResolution: true,
        enumerateDevices: true,
        pixelRatio: true,
        fonts: true
    }
};
Fingerprint2.get(options, (components) => {
    const values = components.map(function (component) {
        return component.value;
    });
    _deviceMetadata.deviceFingerprint = Fingerprint2.x64hash128(values.join(''), 31);
});

export const getDeviceMetadata = () => _deviceMetadata;