import {
    BmgFeatScannerElementBase,
    CLEAR_TECHNICAL_EXCEPTION_EVENT_ID,
    TECHNICAL_EXCEPTION_EVENT_ID
} from "../base/BmgFeatScannerElementBase";
import { html } from "lit";
import { styles } from "./BmgFeatScannerTechnicalError.style";
import { dateToTimestamp } from "../../../util/bmg-time-utils";

export class BmgFeatScannerTechnicalError extends BmgFeatScannerElementBase {
    static get is() {
        return 'bmg-feat-scanner-technical-error';
    }

    static get scopedElements() {
        return {};
    }

    static get properties() {
        return {
            _showTechnicalError: {
                type: Boolean,
            },
            _timestamp: {
                type: String,
            },
        };
    }
    constructor() {
        super();
        this.boundShowAlertErrorHandler = this._handleShowAlertError.bind(this);
        this.boundClearAlertErrorHandler = this._handleClearAlertError.bind(this);
    }

    async connectedCallback() {
        super.connectedCallback();
        window.addEventListener(TECHNICAL_EXCEPTION_EVENT_ID, this.boundShowAlertErrorHandler);
        window.addEventListener(CLEAR_TECHNICAL_EXCEPTION_EVENT_ID, this.boundClearAlertErrorHandler);
    }

    disconnectedCallback() {
        window.removeEventListener(TECHNICAL_EXCEPTION_EVENT_ID, this.boundShowAlertErrorHandler);
        window.removeEventListener(CLEAR_TECHNICAL_EXCEPTION_EVENT_ID, this.boundClearAlertErrorHandler);
        super.disconnectedCallback();
    }

    static get styles() {
        return styles;
    }

    _handleShowAlertError(error) {
        this._showTechnicalError = true;
        const parsedDate = new Date(error?.dateHeader || 'invalid date');
        const isParsedDateValid = !Number.isNaN(parsedDate.valueOf());
        this._timestamp = dateToTimestamp(isParsedDateValid ? parsedDate : new Date());
    }

    _handleClearAlertError() {
        this._showTechnicalError = false;
    }

    render() {
        if (this._showTechnicalError) {
            return html`
            <bmg-alert id="technical-error-alert" class="alertError" type="error">
                <div id="technical-error-alert-main-text">A technical error occurred. Please try again. If the issue persists, send your user ID and a screenshot
                    of the whole page to your BMG Serviceteam via
                    <a class="mail-link" href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a> for support.
                </div>
                <p class="current-timestamp">Timestamp: ${ this._timestamp }</p>
            </bmg-alert>`;
        }
        return html``;
    }
}