import { html } from "lit";
import { BmgFeatScannerElementBase, } from "../../../bmg-feat-scanner/components/base/BmgFeatScannerElementBase";
import { logout, onInactivity, PSD2_INACTIVITY_TIMEOUT } from "@ing-web/token-manager";
import { BmgDialog } from "@bmg-web/bmg-dialog/lib/BmgDialog";
import { LogTraceInterceptor } from "../../../ajax/log-trace-interceptor";


export const ONE_SECOND = 1000;
export const PSD2_INACTIVITY_WARNING_TIMEOUT = 30; // 30 seconds
export const PSD2_INACTIVITY_TIMEOUT_EVENT_ID = 'LEAVE_SITE_INACTIVITY_OCCURRED';
export const SETUP_PSD2_INACTIVITY_HANDLERS = 'PSD2_INACTIVITY_WARNING';
export const SESSION_TIMEOUT_EVENT_ID = 'bmg-feat-scanner-session-timeout';

export class Psd2InactivityWarning extends BmgFeatScannerElementBase {

    static get is() {
        return 'psd2-inactivity-warning';
    }

    static get properties() {
        return {};
    }

    static get scopedElements() {
        return {
            "bmg-dialog": BmgDialog
        };
    }

    constructor() {
        super();
        this.boundHandlePsd2Inactivity = this._logoutOnInactivity.bind(this);
        this.boundSetupPsd2InactivityHandlers = this._setupPsd2InactivityHandlers.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener(SETUP_PSD2_INACTIVITY_HANDLERS, this.boundSetupPsd2InactivityHandlers);
    }

    disconnectedCallback() {
        window.removeEventListener(SETUP_PSD2_INACTIVITY_HANDLERS, this.boundSetupPsd2InactivityHandlers);
        window.removeEventListener(PSD2_INACTIVITY_TIMEOUT_EVENT_ID, this.boundHandlePsd2Inactivity);
        super.disconnectedCallback();
    }

    _setupPsd2InactivityHandlers() {
        window.addEventListener(PSD2_INACTIVITY_TIMEOUT_EVENT_ID, this.boundHandlePsd2Inactivity);
        onInactivity(() => {
            this._openInactivityWarning();
        }, PSD2_INACTIVITY_TIMEOUT - PSD2_INACTIVITY_WARNING_TIMEOUT);
        onInactivity(() => {
            this._logoutOnInactivity();
        }, PSD2_INACTIVITY_TIMEOUT);
    }

    _logoutOnInactivity() {
        this.fireCustomEvent(SESSION_TIMEOUT_EVENT_ID);
        this._closeInactivityWarning();
        LogTraceInterceptor.clearUsername();
        logout();
    }

    _openInactivityWarning() {
        this.shadowRoot.querySelector('#inactivityDialog').open();
    }

    _closeInactivityWarning() {
        this.shadowRoot.querySelector('#inactivityDialog').close();
    }

    render() {
        return html`
            <bmg-dialog
                    dialogTitle="Would you like to continue?"
                    id="inactivityDialog">
                <div slot="dialog-content">
                    Because you are currently not active in Megabank, you will be logged out.
                </div>
                <div slot="dialog-footer">
                    <bmg-button
                            id="confirmDialog"
                            flat dialog-confirm
                            @click="${this._closeInactivityWarning}">
                        continue
                    </bmg-button>
                </div>
            </bmg-dialog>`;
    }
}