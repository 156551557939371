import { amwaApplicationStyle } from "../../../../amwa-application-style";
import { css, unsafeCSS } from 'lit';
import { bmgBlack87, bmgBlue, fontRegular, fontRmd, fontSizeMl } from "@bmg-web/bmg-styles";

export const styles = css`
  ${ amwaApplicationStyle }

  .means-choice-container {
    padding: 16px;
  }
  
  .step-title {
    ${ unsafeCSS(fontSizeMl) }
    ${ unsafeCSS(fontRegular) }
    color: ${ unsafeCSS(bmgBlue) };
  }

  .step-note-text {
    ${ unsafeCSS(fontRmd) }
    color: ${ unsafeCSS(bmgBlack87) };
    margin: 16px 0;
  }
  
  .button-group-container {
    width: 100%;
    height: 36px;
  }
`;
