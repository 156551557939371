import { css, html, LitElement, unsafeCSS } from 'lit';
import '@bmg-web/bmg-ripple';
import { bmgBlack12 } from '@bmg-web/bmg-styles';
import { amwaApplicationStyle } from '../amwa-application-style';
import '@bmg-web/bmg-icon';


export class AmwaLoginLink extends LitElement {

    static get styles() {
        return [amwaApplicationStyle,
            css`
                .container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-bottom: 12px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid ${unsafeCSS(bmgBlack12)};
                    cursor: pointer;
                }

            `,
        ];
    }

    render() {

        return html`
            <div class="container">
                <div class="text">
                    <slot id="content"></slot>
                </div>
                <div class="link-icon">
                    <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                </div>
            </div>

        `;
    }
}

customElements.define('amwa-login-link', AmwaLoginLink);
