import { apiGatewayAjax } from "../../ajax/api-gateway-ajax";

export class CrontoHardwareTokenService {

    static requestScannerImage = (endpoint, data, headers = {}) =>
        apiGatewayAjax.post(endpoint, data, { headers });

    static getIdentificationImage = (endpoint, headers = {}) =>
        apiGatewayAjax.get(endpoint, { headers });

    static sendScannerResponse = (endpoint, headers, request) =>
        apiGatewayAjax.post(endpoint, request, { headers });

}