import { PREFILL_EVENT_ID } from "../../../ajax/prefill-interceptor";
import { BmgFeatScannerElementBase } from "./BmgFeatScannerElementBase";


export class BmgFeatScannerPrefillable extends BmgFeatScannerElementBase {

    _handlePrefill = (event) => {
        const { detail } = event;
        const element = this.shadowRoot.querySelector( detail.el );
        if (!element) {
            console.warn(`Element ${ detail.el } not found`);
            return;
        }
        // Set the value and re-focus to apply mask formatting
        element.inputElement.value = detail.value;
        element.refreshAttributes();
        element.blur();
        element.focus();
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener(PREFILL_EVENT_ID, this._handlePrefill);
    }

    disconnectedCallback() {
        window.removeEventListener(PREFILL_EVENT_ID, this._handlePrefill);
        super.disconnectedCallback();
    }
}