export const TransferAccessTokenTypes = {
    createTransferOtp: {
        endpoint: '/security-means/transfers',
    },
    accessToken: {
        endpoint: '/security-means/transfers/access-tokens',
    }
};

export class AccessTokenRetrieveRequest {
    constructor(otp, authenticationContext, pinning) {
        this.otp = otp;
        this.authenticationContext = authenticationContext;
        this.pinning = pinning;
    }
}

export class AuthenticationContext {
    constructor(clientId, requiredLevelOfAssurance, identifyeeType, scopes) {
        this.clientId = clientId;
        this.requiredLevelOfAssurance = requiredLevelOfAssurance;
        this.identifyeeType = identifyeeType;
        this.scopes = scopes;
    }
}

export class Pinning {
    constructor(type, value) {
        this.type = type;
        this.value = value;
    }
}


export class accessTokenRetrieveResponse {
    constructor(authentication, person, accessTokens, originatingProfileId) {
        this.authentication = authentication;
        this.person = person;
        this.accessTokens = accessTokens;
        this.originatingProfileId = originatingProfileId;
    }
}

export class Authentication {
    constructor(status, means) {
        this.status = status;
        this.means = means;
    }
}

export class Means {
    constructor(name, componentName, status, metadata) {
        this.name = name;
        this.componentName = componentName;
        this.status = status;
        this.metadata = metadata;
    }
}

export class Person {
    constructor(id) {
        this.id = id;
    }
}

export class AccessToken {
    constructor(
        accessToken,
        accessTokenExpirationDate,
        accessTokenTimeToLive,
        refreshToken,
        refreshTokenExpirationDate,
        refreshTokenTimeToLive,
        profileId,
        profileName,
        isDefault,
        jwtId,
        setId
    ) {
        this.accessToken = accessToken;
        this.accessTokenExpirationDate = accessTokenExpirationDate;
        this.accessTokenTimeToLive = accessTokenTimeToLive;
        this.refreshToken = refreshToken;
        this.refreshTokenExpirationDate = refreshTokenExpirationDate;
        this.refreshTokenTimeToLive = refreshTokenTimeToLive;
        this.profileId = profileId;
        this.profileName = profileName;
        this.isDefault = isDefault;
        this.jwtId = jwtId;
        this.setId = setId;
    }
}

export class TransferOtpResponse {
    constructor(transferAccessTokenOtp) {
        this.transferAccessTokenOtp = transferAccessTokenOtp;
    }

}
