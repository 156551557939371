import { html } from "lit";

const _activationHelpItemsData = [
    {
        situation: "Where can I find my user ID?",
        resolution: html`
            <div class="resolution-body">You received your user ID via email when your Megabank user profile was created. The subject of this email is ‘Megabank access for [your name]’.</div>
            <div class="resolution-body">If you no longer have this email, you can contact your coordinator or your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a> </div>
            <div class="resolution-subject">Do you have an alternative to access Megabank?</div>
            <div class="resolution-body">If you have access to Megabank with another access method, you can find your user ID in 'My profile'. If you have the mobile app, you can find your User ID in the 'Profile' section</div>
        `
    },
    {
        situation: "My email address registered in Megabank might no longer be correct. What to do?",
        resolution: html`
            <div class="resolution-body">Contact your coordinator to check which mail address is registered in Megabank. If this is incorrect, ask your coordinator to update your email address. Restart the activation on <a href="https://www.megabank.nl/scanner" target="_blank">www.megabank.nl/scanner</a> as soon as the change of your email address has been processed.</div>
            <div class="resolution-body">Alternatively, contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a> </div>
        `
    },
    {
        situation: "What to do if I did not receive a registration code via email?",
        resolution: html`
            <div class="resolution-body">Sometimes company mail servers cause a delay due to virus scanning and internal routing. Please wait a couple of minutes, the email should arrive soon. Meanwhile check your spam folder.</div>
            <div class="resolution-body">Still no registration code? Restart the scanner activation process on <a href="https://www.megabank.nl/scanner" target="_blank">www.megabank.nl/scanner</a>.</div>
            <div class="resolution-body">If the issue persists, contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.</div>
        `
    },
    {
        situation: "What to do if the registration code did not work?",
        resolution: html`
            <div class="resolution-body">The registration code is valid for 10 minutes. If this time has passed, restart the scanner activation on <a href="https://www.megabank.nl/scanner" target="_blank">www.megabank.nl/scanner</a>.</div>
            <div class="resolution-body">If this doesn’t help, restart the scanner activation process on <a href="https://www.megabank.nl/scanner" target="_blank">www.megabank.nl/scanner</a>.</div>
            <div class="resolution-body">If – after a few restarts – the issue persists, contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.</div>
        `
    },
    {
        situation: "What to do if I could not complete the activation process?",
        resolution: html`
            <div class="resolution-body">Restart the process on <a href="https://www.megabank.nl/scanner" target="_blank">www.megabank.nl/scanner</a>.</div>
            <div class="resolution-body">If your scanner displays the question 'Sorry you have already used this color code. Do you want to start activation again'? Choose 'Yes'</div>
            <div class="resolution-body">If the next screen shows 'Do you want to remove this user account?' Choose 'Yes'</div>
            <div class="resolution-body">If – after a few restarts – the issue persists, contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.</div>
        `
    },
    {
        situation: "What to do if my scanner does not work?",
        resolution: html`
            <div class="resolution-body">Make sure you have put in the batteries correctly. If needed, replace them with new AAA/LR03 type batteries.</div>
            <div class="resolution-body">If this doesn’t help, contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.</div>
        `
    },
    {
        situation: "How do I know which scanner is mine?",
        resolution: html`
            <div class="resolution-body">Your scanner will accept your PIN code when using it.</div>
            <div class="resolution-body">Still not sure? If you have activated a scanner, it becomes your personal device. You can recognize an individual scanner by the serial number on the back. Your coordinator or BMG service team can see which serial number is linked to your account. Ask your coordinator for the serial number or contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.</div>
        `
    }
];

const _logonHelpItemsData = [
    {
        situation: "Where can I find my user ID?",
        resolution: html`
            <div class="resolution-body">You received your user ID via email when your Megabank user profile was created. The subject of this email is ‘Megabank access for [your name]’.</div>
            <div class="resolution-body">If you no longer have this email, you can contact your coordinator or your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a></div>
            <div class="resolution-subject">Do you have an alternative to access Megabank?</div>
            <div class="resolution-body">If you have an alternative to access Megabank, you can find your user ID in Megabank. Log on and go to 'My profile' in the top right corner. You can find your user ID under 'My personal information'.</div>
        `
    },
    {
        situation: "I forgot my PIN. What to do?",
        resolution: html`
            <div class="resolution-body">A scanner can get locked when you enter a wrong PIN 5 times. This is what to do:</div>
            <div class="resolution-body">First, ask your coordinator to deactivate your scanner or contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>. You will receive a confirmation email once your device has been deactivated.</div>
            <div class="resolution-body">Then, you can reactivate your scanner on <a href="https://www.megabank.nl/scanner" target="_blank">www.megabank.nl/scanner</a>.</div>
        `
    },
    {
        situation: "How can I log on with my new scanner?",
        resolution: html`
            <div class="resolution-body">First, activate your scanner on <a href="https://www.megabank.nl/scanner" target="_blank">www.megabank.nl/scanner</a>. After activation you can log on to Megabank with your scanner.</div>
            <div class="resolution-body">Then, go to www.megabank.nl and choose ‘Log on with scanner’.</div>
        `
    },
    {
        situation: "What to do if I have trouble scanning the color code?",
        resolution: html`
            <div class="resolution-body">Make sure you have put in the batteries correctly. If needed, replace them with new AAA/LR03 type batteries.</div>
            <div class="resolution-body">Before you can log on with your scanner, you need to activate it once. You can do so on <a href="https://www.megabank.nl/scanner" target="_blank">www.megabank.nl/scanner</a>. Make sure to complete the activation process in one go.</div>
            <div class="resolution-body">If this doesn’t help, contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.</div>
        `
    },
    {
        situation: "What to do if my scanner does not work?",
        resolution: html`
            <div class="resolution-body">Make sure you have put in the batteries correctly. If needed, replace them with new AAA/LR03 type batteries.</div>
            <div class="resolution-body">If this doesn’t help, ask your coordinator for a new scanner or contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.</div>
        `
    },
    {
        situation: "My scanner got lost or stolen. What to do?",
        resolution: html`
            <div class="resolution-body">Report the incident to your coordinator or to your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a> immediately. They will help you regain access to Megabank.</div>
        `
    },
    {
        situation: "What to do when my scanner returns 'The color code you scanned was not intended for this scanner.'?",
        resolution: html`
            <div class="resolution-body">Please check whether your user ID is correct. Not sure what your user ID is? Check the help item 'Where can I find my user ID'</div>
            <div class="resolution-body">If your user ID is correct, you might be using the scanner of someone else. Please check the help item 'How do I know which scanner is mine?'</div>
        `
    },
    {
        situation: "How do I know which scanner is mine?",
        resolution: html`
            <div class="resolution-body">Your scanner will accept your PIN code when using it.</div>
            <div class="resolution-body">Still not sure? If you have activated a scanner, it becomes your personal device. You can recognize an individual scanner by the serial number on the back. Your coordinator or BMG service team can see which serial number is linked to your account. Ask your coordinator for the serial number or contact your BMG service team via <a href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.</div>
        `
    }
];

const activationHelpItems = new Map(
    _activationHelpItemsData.map(datum => [datum.situation, datum.resolution])
);

const logonHelpItems = new Map(
    _logonHelpItemsData.map(datum => [datum.situation, datum.resolution])
);

export const activationHelpDialogModel = {
    title: "Help",
    items: activationHelpItems
};

export const logonHelpDialogModel = {
    title: "Help",
    items: logonHelpItems
};