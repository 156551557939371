import { html } from 'lit';
import { styles } from "./BmgFeatScannerFinishSetup.style";
import "@bmg-web/bmg-form/bmg-form.js";
import "@bmg-web/bmg-button/bmg-button.js";
import "../../components/help-dialog/bmg-feat-scanner-help-dialog.js";
import { CrontoHardwareTokenService } from "../../services/cronto-hardware-token.service";
import { crontoTypes } from "../../model/cronto-type";
import { INVALID_SCANNER_MEANS_AGREEMENT_ERROR_CODE, SUCCESS } from "../../components/base/BmgFeatScannerElementBase";
import { ScannerService } from "../../services/scanner.service";
import { activationHelpDialogModel } from "../../model/help-dialog-model";
import { AmwaMaskedInput } from "../../../components/amwa-input/AmwaMaskedInput";
import {
  BmgFeatScannerCrontoImage,
  CRONTO_IMAGE_EXPIRED_EVENT_ID
} from "../../components/cronto-image/BmgFeatScannerCrontoImage";
import { BmgFeatScannerPrefillable } from "../../components/base/BmgFeatScannerPrefillable";


const INVALID_CONFIRMATION_CODE_FORMAT_ERROR_MESSAGE = "The format is not valid. Please enter a 15 digit code.";
const INVALID_CONFIRMATION_CODE_ERROR_MESSAGE = "The confirmation code is not valid. Please check and try again.";
const EXCEEDED_CONFIRMATION_CODE_ATTEMPTS_ERROR_MESSAGE = "You have exceeded the number of attempts to enter a confirmation code. You have received an email with instructions on how to regain access to Megabank.";

export class BmgFeatScannerFinishSetup extends BmgFeatScannerPrefillable {
  static get is() {
    return 'bmg-feat-scanner-finish-setup';
  }

  static get scopedElements() {
    return {
      "amwa-masked-input": AmwaMaskedInput,
    };
  }

  static get properties() {
    return {
      _crontoParams: {
        type: Object,
      },
      userId: {
        type: String,
        attribute: 'user-id',
      },
      _crontoImageExpired: {
        type: Boolean,
        attribute: false
      },
      _encryptedState: {
        type: String,
        attribute: 'encrypted-state'
      },
      _activationProgress: {
        type: Boolean,
        attribute: false
      }
    };
  }

  constructor() {
    super();
    this._crontoImageExpired = false;
    this._activationProgress = false;
  }

  static get styles() {
    return styles;
  }

  get _confirmationCodeElement() {
    return this.shadowRoot.querySelector('#inputConfirmationCode') || {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener(BmgFeatScannerCrontoImage.is + SUCCESS, this._handleCrontoImageAppear);
    this.addEventListener(CRONTO_IMAGE_EXPIRED_EVENT_ID, this._handleCrontoImageExpired);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener(BmgFeatScannerCrontoImage.is + SUCCESS, this._handleCrontoImageAppear);
    this.removeEventListener(CRONTO_IMAGE_EXPIRED_EVENT_ID, this._handleCrontoImageExpired);
  }

  _handleCrontoImageAppear = ({ detail }) => {
    this._crontoParams = detail;
    this._crontoImageExpired = false;
  }

  _handleCrontoImageExpired = () => {
    this._crontoImageExpired = true;
  }

  _resetValidationErrors() {
    this._confirmationCodeElement.invalid = false;
    this._confirmationCodeElement.errorMessage = INVALID_CONFIRMATION_CODE_FORMAT_ERROR_MESSAGE;
  }

  _setErrorConfirmationCode(errorMessage) {
    this._confirmationCodeElement.invalid = true;
    this._confirmationCodeElement.errorMessage = errorMessage;
  }

  _handleInvalidConfirmationCodeError() {
    this._setErrorConfirmationCode(INVALID_CONFIRMATION_CODE_ERROR_MESSAGE);
  }

  _handleConfirmationCodeAttemptsExceededError() {
    this._setErrorConfirmationCode(EXCEEDED_CONFIRMATION_CODE_ATTEMPTS_ERROR_MESSAGE);
  }

  _handleNext() {
    this._clearTechnicalError();
    this._resetValidationErrors();
    const confirmationCodeElement = this._confirmationCodeElement;
    const isConfirmationCodeValid = confirmationCodeElement.validate();
    if (!isConfirmationCodeValid) {
      this._confirmationCodeElement.invalid = true;
      console.error("Validation error");
      return;
    }

    this._activationProgress = true;
    this._handleFinishSetup(confirmationCodeElement);
  }

  _handleFinishSetup(confirmationCodeElement) {
    CrontoHardwareTokenService.requestScannerImage(
      crontoTypes.activateDevice.response,
      { tokenResponse: confirmationCodeElement.unmaskedValue },
      { 'x-ing-crontoencryptedstate': this._crontoParams.encryptedState || '' }, // TODO: should be handled by interceptor. see story #4552695
    )
      .then(() => ScannerService.finishActivation({ userName: this.userId }))
      .then(response => this._handleFinishSetupSuccess( response.data ))
      .catch(error => this._handleFinishSetupError( error ))
  }

  _handleFinishSetupSuccess(finishActivationResponse) {
    const { levelOfAssurance } = finishActivationResponse;
    if ( levelOfAssurance !== 1 && levelOfAssurance !== 2 ) { // TODO: remove this if this situation never happened
      console.error("Invalid LoA after scanner activation!", {finishActivationResponse});
      const error = new Error("Invalid LoA after scanner activation");
      error.code = INVALID_SCANNER_MEANS_AGREEMENT_ERROR_CODE;
      this.handleError(BmgFeatScannerFinishSetup.is, error);
    } else {
      this.fireCustomEvent( BmgFeatScannerFinishSetup.is + SUCCESS, { levelOfAssurance } );
    }

  }

  _handleFinishSetupError(error) {
    this._activationProgress = false;
    const status = error?.response.status;

    if (status === 400) {
      this._handleInvalidConfirmationCodeError();
    } else if (status === 403) {
      this._handleConfirmationCodeAttemptsExceededError();
    } else {
      this.handleError(BmgFeatScannerFinishSetup.is, error);
    }
  }

  _renderCrontoImage() {
    return html`
        <bmg-feat-scanner-cronto-image
            cronto-type="activateDevice"
            encrypted-state="${ this._encryptedState }"
        ></bmg-feat-scanner-cronto-image>
    `;
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">4. Finish setting up your scanner</div>
        <bmg-form id="bmgForm" name="form">
            <ul class="info-bullets">
                <li>Scan the color code below with your scanner.</li>
            </ul>

            ${ this._renderCrontoImage() }
            
            <ul class="info-bullets">
                <li>
                    Your scanner will show another confirmation code.
                    Enter it below, then click 'Next'.
                </li>
            </ul>
            <div class="form-container">
                <amwa-masked-input
                        id="inputConfirmationCode"
                        name="confirmationCode"
                        label="Confirmation code"
                        autofocus
                        required
                        minlength="11"
                        maxLength="19"
                        mask="000 000 000 000 000"
                        ?disabled="${ this._crontoImageExpired }"
                        .errorMessage="${ INVALID_CONFIRMATION_CODE_FORMAT_ERROR_MESSAGE }"
                ></masked-input>
            </div>
            <bmg-button class="button" @click=${ this._handleNext } .disabled="${ this._crontoImageExpired  || this._activationProgress }">Next</bmg-button>
            <bmg-button class="right" flat inverted @click=${ this._openHelpDialog }>Need help?</bmg-button>
            <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
        </bmg-form>
    `;
  }
}