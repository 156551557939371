
export const PREFILL_EVENT_ID = 'bmg-feat-scanner-prefill';

export class PrefillInterceptor {

  static _prefillConfig = {};

  static prefillInterceptor = (response) => {
    const { url } = response.config;
    const { pathname } = new URL( url );

    const prefillElement = PrefillInterceptor._prefillConfig[pathname];
    const prefillValue = response.headers[PrefillInterceptor._prefillConfig.header];
    if (prefillElement && prefillValue) {
      const detail = { el: prefillElement, value: prefillValue };
      const eventInit = { bubbles: true, composed: true, detail };
      const prefillEvent = new CustomEvent( PREFILL_EVENT_ID, eventInit );
      window.dispatchEvent( prefillEvent );
    }

    return response;
  };

  static setConfig = (config) => {
    this._prefillConfig = config;
  };

}