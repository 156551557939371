import { styles } from './BmgFeatScannerCrontoImage.style.js';
import { CrontoHardwareTokenService } from '../../services/cronto-hardware-token.service.js';
import { html } from "lit";
import "@bmg-web/bmg-spinner";
import { CrontoType, crontoTypes } from "../../model/cronto-type";
import { BmgFeatScannerElementBase, SUCCESS } from "../base/BmgFeatScannerElementBase";
import { BmgFeatScannerActivation } from "../../activation/BmgFeatScannerActivation";
import { BmgFeatScannerLogon } from "../../logon/BmgFeatScannerLogon";


export const CRONTO_IMAGE_EXPIRED_EVENT_ID = 'bmg-feat-scanner-cronto-image-expired';
export const CRONTO_IMAGE_INIT_REFRESH_EVENT_ID = 'bmg-feat-scanner-cronto-init-image-refresh';
export const CRONTO_IMAGE_REFRESH_EVENT_ID = 'bmg-feat-scanner-cronto-image-refresh';
export const CRONTO_IMAGE_REFRESHED_EVENT_ID = 'bmg-feat-scanner-cronto-image-refreshed';

const CRONTO_IMAGE_EXPIRED_URL = "/bmg-access-means/v1/static/image/bmg-cronto-image.png";

export class BmgFeatScannerCrontoImage extends BmgFeatScannerElementBase {
    static get is() {
        return 'bmg-feat-scanner-cronto-image';
    }

    static get styles() {
        return styles;
    }

    static get properties() {
        return {
            crontoType: {
                type: String,
                attribute: 'cronto-type',
            },
            // The observer is a special AngularJS workaround
            // AngularJS in the beginning passed something like {{authenticationId}}
            // and only in the next tick the correct value
            basketId: {
                type: String,
                observer: '_getCronto', // todo: replace observer
            },
            encryptedState: {
                type: String,
                attribute: 'encrypted-state'
            },
            serialNumber: {
                type: String,
                attribute: 'serial-number'
            },
            userId: {
                type: String,
                attribute: 'user-id',
            },
            authenticationContext: {
                type: String,
                attribute: 'authentication-context',
            },
            _crontoImageExpired: {
                type: Boolean
            },
            _crontoImageSrc: {
                type: String
            }
        };
    }

    get _bmgFeatScannerComponents() {
        return [
            ...Object.keys(BmgFeatScannerActivation.scopedElements),
            ...Object.keys(BmgFeatScannerLogon.scopedElements),
        ];
    }

    constructor() {
        super();

        this.boundStopRefreshToken = this._stopTokenRefresh.bind(this);
        this._crontoImageExpired = false;
    }

    connectedCallback() {
        super.connectedCallback();
        this._addEventListeners();
        this._loading = true;
        this._getCronto();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._removeEventListeners();
        this._stopTokenRefresh();
    }

    get _authenticationContextObj() {
        if (!this.authenticationContext) {
            this.handleError(BmgFeatScannerCrontoImage.is + '-error');
            throw new Error("Unexpected error");
        }
        return JSON.parse(this.authenticationContext);
    }

    _addEventListeners() {
        this._bmgFeatScannerComponents.forEach((componentName) => {
            document.addEventListener(componentName + SUCCESS, this.boundStopRefreshToken);
        });
        this.addEventListener(CRONTO_IMAGE_REFRESH_EVENT_ID, this._handleCrontoImageRefresh);
    }

    _removeEventListeners() {
        this._bmgFeatScannerComponents.forEach((componentName) => {
            document.removeEventListener(componentName + SUCCESS, this.boundStopRefreshToken);
        });
        this.removeEventListener(CRONTO_IMAGE_REFRESH_EVENT_ID, this._handleCrontoImageRefresh);
    }

    _stopTokenRefresh() {
        clearTimeout(this._refreshTimeout);
        clearTimeout(this._refreshInnerTimeout);
    }

    _getCronto() {
        if (!this._isValidRequest()) {
            this._handleError({ code: 'BAD_REQUEST', response: { status: 400 } });
            return;
        }

        const endpoint = crontoTypes[this.crontoType].token;
        const requestBody = this._getRequestBody();
        const headers = this._getRequestHeaders();
        console.log('Getting cronto image for:', { type: this.crontoType, endpoint, headers, requestBody }); // TODO: remove logging

        const crontoApiCallPromise = (this.crontoType === CrontoType.SERIAL_NUMBER)
            ? CrontoHardwareTokenService.getIdentificationImage( endpoint, headers )
            : CrontoHardwareTokenService.requestScannerImage( endpoint, requestBody, headers );

        crontoApiCallPromise.then(
            (response) => this._handleSuccess( response ),
            (error) => this._handleError( error )
        );
    }

    _isValidRequest() {
        if (crontoTypes[this.crontoType]) {
            if (this.crontoType === CrontoType.ACTIVATE_DEVICE && !this.encryptedState) {
                return false;
            }
            if (this.crontoType === CrontoType.ENROL_DEVICE && !this.serialNumber) {
                return false;
            }
            if (this.crontoType === CrontoType.AUTHENTICATION && !this.authenticationContext) {
                return false;
            }
            if (this.crontoType === CrontoType.AUTHENTICATION_STANDALONE && !this.userId) {
                return false;
            }
            return true;
        }
        return false;
    }

    _getRequestBody() {
        if (this.crontoType === CrontoType.ENROL_DEVICE) {
            return {
                serialNumber: this.serialNumber,
            };
        }
        if (this.crontoType === CrontoType.AUTHENTICATION) {
            return this.authenticationContext;
        }
        if (this.crontoType === CrontoType.AUTHENTICATION_STANDALONE) {
            return {
                userId: this.userId,
                cso: 'CSO_BMG',
                authenticationContext: this._authenticationContextObj,
            }
        }
        return null;
    }

    _getRequestHeaders() {
        const headers = {'Content-Type': 'application/json;charset=UTF-8'};
        if (this.encryptedState) {
            headers['X-ING-CrontoEncryptedState'] = this.encryptedState; // TODO: should be handled by interceptor. see story #4552695
        }
        return headers;
    }

    _handleSuccess(response) {
        if (this._hasValidResponseBody(response) && this._hasValidHeaders(response)) {
            this._handleCrontoImageExpired(response.data.expirationDuration);

            this._crontoImageSrc = `data:image/png;base64,${ response.data.base64Cronto }`;

            this._fireSuccessEvent(
                response.data.responseCheckDigit,
                response.data.responseLength,
                response.headers['x-ing-crontoencryptedstate'], // TODO: should be handled by interceptor. see story #4552695
                response.data.serialNumber
            );
            // Hide spinner
            this._loading = false;
            this.requestUpdate();
        } else {
            this._handleError({ code: 'INVALID_RESPONSE', response: { status: 500 }  });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    _hasValidResponseBody(response) {
        return response.data
            && (response.data.responseCheckDigit != null)
            && (response.data.responseLength >= 6
                && response.data.responseLength <= 20);
    }

    // eslint-disable-next-line class-methods-use-this
    _hasValidHeaders(response) {
        if (this.crontoType === CrontoType.SERIAL_NUMBER) return true;
        return (response.headers && response.headers['x-ing-crontoencryptedstate']) // TODO: should be handled by interceptor. see story #4552695
    }

    _handleError(error) {
        this.handleError(BmgFeatScannerCrontoImage.is + '-error', error);
        this._stopTokenRefresh();
    }

    _fireSuccessEvent(responseCheckDigit, responseLength, encryptedState, serialNumber) {
        this.fireCustomEvent(BmgFeatScannerCrontoImage.is + SUCCESS, {
            responseCheckDigit,
            responseLength,
            encryptedState,
            serialNumber,
        });
    }

    _handleCrontoImageExpired (expirationDuration) {
        if (expirationDuration) {
            const milliSecondsTillExpiration = expirationDuration * 1000;
            this._refreshTimeout = setTimeout(() => {
                this._crontoImageExpired = true;
                this.fireCustomEvent(CRONTO_IMAGE_EXPIRED_EVENT_ID);
            }, milliSecondsTillExpiration - 10000);
        }
    }

    _handleRefreshOfCrontoImage() {
            if (this.crontoType === CrontoType.AUTHENTICATION
              ||this.crontoType === CrontoType.AUTHENTICATION_STANDALONE) {
                this.fireCustomEvent(CRONTO_IMAGE_INIT_REFRESH_EVENT_ID);
            } else {
                this.fireCustomEvent(CRONTO_IMAGE_REFRESH_EVENT_ID);
            }
    }

    _handleCrontoImageRefresh () {
        this._crontoImageExpired = false;
        this._loading = true;
        this._getCronto();
        this._refreshInnerTimeout = setTimeout(() => {
            this._loading = false;
            this.requestUpdate();
            this.fireCustomEvent(CRONTO_IMAGE_REFRESHED_EVENT_ID);
        }, 3000);
    }


    _renderSpinner() {
        return html`
            <bmg-spinner id="spinner" active></bmg-spinner>
        `;
    }

    _renderCrontoImageExpired() {
        return html`
            <img
                    id="cronto-image"
                    class="img-responsive expired"
                    alt="cronto"
                    src=${ CRONTO_IMAGE_EXPIRED_URL }
            />
            <bmg-button
                    id="refresh-cronto-button"
                    class="button centered-button"
                    @click="${ this._handleRefreshOfCrontoImage }"
            >
                Refresh
            </bmg-button>
        `;
    }

    _renderCrontoImageSuccess() {
        return html`
            ${ this._loading ? this._renderSpinner() : '' }
            <img
                    id="cronto-image"
                    class="img-responsive"
                    ?hidden="${ this._loading }"
                    alt="cronto"
                    src=${ this._crontoImageSrc }
            />
        `;
    }

    render() {
        return html`
            <div class="cronto-container">
                ${ this._crontoImageExpired ? this._renderCrontoImageExpired() : this._renderCrontoImageSuccess() }
            </div>
        `;
    }
}
