import { css, unsafeCSS } from 'lit';
import { amwaApplicationStyle } from "../../amwa-application-style";
import { bmgLabelError } from "@bmg-web/bmg-styles/typography";
import { bmgBlue, bmgBlue18, fontRegular, fontSizeMl } from "@bmg-web/bmg-styles";

export const scannerLogonStyle = css`

  ${ amwaApplicationStyle }
  
  :host {
    display: block;
  }

  .form-container {
      background-color: ${ unsafeCSS(bmgBlue18) };
      margin-top: 16px;
      margin-bottom: 16px;
      padding: 16px;
  }
  
  .step-title {
    ${ unsafeCSS(fontSizeMl) }
    ${ unsafeCSS(fontRegular) }
    color: ${ unsafeCSS(bmgBlue) };
  }

  #generic-validation-error {
    padding: 0 0 8px 0;
    margin: 0 0 16px 0;
    overflow: hidden;
    ${ unsafeCSS(bmgLabelError) };
  }
  
  [hidden] {
    display: none;
  }

  .button {
    margin-bottom: 16px;
  }
`;
