import { html, LitElement } from 'lit';
import '@bmg-web/bmg-card';
import { amwaApplicationStyle } from '../amwa-application-style';
import { ScopedElementsMixin } from "@open-wc/scoped-elements";
import {AmwaLinkIbUserChoice} from "../components/amwa-link-ib-user-choice";

class LinkIbUserChoicePage extends ScopedElementsMixin(LitElement) {

    static get scopedElements() {
      return {
        "amwa-link-ib-user-choice": AmwaLinkIbUserChoice,
      };
    }

    static get styles() {
        return [
            amwaApplicationStyle,
        ];
    }

    render() {
        return  html`
            <amwa-link-ib-user-choice></amwa-link-ib-user-choice>
        `;
    }
}

customElements.define('link-ib-user-choice-page', LinkIbUserChoicePage);
