import { html } from 'lit';
import { styles } from './BmgFeatScannerLogonScanCode.style.js';
import { GO_BACK, SUCCESS } from "../../components/base/BmgFeatScannerElementBase";
import "@bmg-web/bmg-form/bmg-form.js";
import "@bmg-web/bmg-button/bmg-button.js";
import "@bmg-web/bmg-spinner";
import "../../components/cronto-image/bmg-feat-scanner-cronto-image.js"
import "../../components/help-dialog/bmg-feat-scanner-help-dialog";
import { CrontoType, crontoTypes } from "../../model/cronto-type";
import { BmgForm } from "@bmg-web/bmg-form/lib/BmgForm";
import { BmgButton } from "@bmg-web/bmg-button/lib/BmgButton";
import { AMWA_TP_CLIENT_ID, ANONYMOUS_AUTHENTICATION_CONTEXT } from "../../../util/service-utils";
import { TransferAccessTokenService } from "../../../components/authentication/transfer-access-token.service";
import { ScannerService } from "../../services/scanner.service";
import { SessionService } from "../../services/session.service";
import { CrontoHardwareTokenService } from "../../services/cronto-hardware-token.service";
import {
  BmgFeatScannerCrontoImage,
  CRONTO_IMAGE_EXPIRED_EVENT_ID,
  CRONTO_IMAGE_INIT_REFRESH_EVENT_ID,
  CRONTO_IMAGE_REFRESH_EVENT_ID
} from "../../components/cronto-image/BmgFeatScannerCrontoImage";
import { logonHelpDialogModel } from "../../model/help-dialog-model";
import { initAnonymousSession, logout } from "@ing-web/token-manager";
import { getDeviceMetadata } from "../../../util/device-metadata-service";
import { AmwaMaskedInput } from "../../../components/amwa-input/AmwaMaskedInput";
import { CheckDigitValidationService } from "../../services/check-digit-validation-service";
import { COOKIE_KEY, setCookie } from "../../../util/cookie-service";
import { AmwaInput } from "../../../components/amwa-input/AmwaInput";
import { BmgFeatScannerPrefillable } from "../../components/base/BmgFeatScannerPrefillable";


const INVALID_CONFIRMATION_CODE_FORMAT_ERROR_MESSAGE = "The format of the code is incorrect. Please try again.";
const INVALID_CONFIRMATION_CODE_ERROR_MESSAGE = "The combination of user id and code is not correct. Please check both user id and code and try again. You have 5 attempts before your scanner gets deactivated.";
const EXCEEDED_CONFIRMATION_CODE_ATTEMPTS_ERROR_MESSAGE = "You have exceeded the number of attempts to enter a code. You have received an email with instructions on how to regain access to Megabank.";

const LOGON_AUTHENTICATION_CONTEXT = {
  clientId: AMWA_TP_CLIENT_ID,
  scopes: [ "personal_data" ],
  requiredLevelOfAssurance: 5,
  identifyeeType: "customer"
};

export class BmgFeatScannerLogonScanCode extends BmgFeatScannerPrefillable {
  static get is() {
    return 'bmg-feat-scanner-logon-scan-code';
  }

  static get scopedElements() {
    return {
      "bmg-form": BmgForm,
      "amwa-input": AmwaInput,
      "bmg-button": BmgButton,
      "amwa-masked-input": AmwaMaskedInput,
    };
  }

  static get properties() {
    return {
      userId: {
        type: String,
        attribute: "user-id"
      },
      _canCallCronto: {
        type: Boolean,
      },
      _crontoParams: {
        type: Object,
      },
      checkDigit: {
        type: Boolean,
        attribute: 'check-digit',
      },
      _crontoImageExpired: {
        type: Boolean,
        attribute: false
      },
      _logonRequestInProgress: {
        type: Boolean,
        attribute: false
      },
    };
  }

  static get styles() {
    return styles;
  }

  constructor() {
    super();
    this._crontoImageExpired = false;
    this._logonRequestInProgress = false;
  }

  get _confirmationCodeElement() {
    return this.shadowRoot.querySelector('#inputConfirmationCode');
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _handleCrontoImageAppear = ({ detail }) => {
    this._crontoParams = detail;
    this._crontoImageExpired = false;
  }

  _handleCrontoImageExpired = () => {
    this._crontoImageExpired = true;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.addEventListener(BmgFeatScannerCrontoImage.is + SUCCESS, this._handleCrontoImageAppear);
    this.addEventListener(CRONTO_IMAGE_INIT_REFRESH_EVENT_ID, this._handleCrontoImageInitRefresh);
    this.addEventListener(CRONTO_IMAGE_EXPIRED_EVENT_ID, this._handleCrontoImageExpired);

    // Anonymous session is needed for Cronto API calls
    await initAnonymousSession( ANONYMOUS_AUTHENTICATION_CONTEXT )
        .then(() => this._canCallCronto = true)
        .catch((error) => this.handleError(BmgFeatScannerLogonScanCode.is, error));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener(BmgFeatScannerCrontoImage.is + SUCCESS, this._handleCrontoImageAppear);
    this.addEventListener(CRONTO_IMAGE_INIT_REFRESH_EVENT_ID, this._handleCrontoImageInitRefresh);
    this.removeEventListener(CRONTO_IMAGE_EXPIRED_EVENT_ID, this._handleCrontoImageExpired);
  }

  _handleCrontoImageInitRefresh() {
    this._canCallCronto = false;
    initAnonymousSession(ANONYMOUS_AUTHENTICATION_CONTEXT)
      .then(() => this._canCallCronto = true)
      .then(() => this._crontoImageExpired = false)
      .then(() => this.fireCustomEvent(CRONTO_IMAGE_REFRESH_EVENT_ID))
      .catch((error) => this.handleError(BmgFeatScannerLogonScanCode.is, error));
  }

  _handleBack() {
    this._clearTechnicalError();
    this.fireCustomEvent( BmgFeatScannerLogonScanCode.is + GO_BACK);
  }

  _authenticateWithConfirmationCode = (tokenResponse) =>
      CrontoHardwareTokenService.sendScannerResponse(
          crontoTypes.authenticationStandalone.response,
          {
            'X-ING-CrontoEncryptedState': this._crontoParams.encryptedState // TODO: should be handled by interceptor. see story #4552695
          },
          { tokenResponse }
      );


  _createTransferOtp = ( accessToken ) =>
      TransferAccessTokenService.initiateTransferToSsoService( accessToken );

  _logonToMegabank = ( transferOtp ) =>
      ScannerService.logon({
        username: this.userId,
        transferOtp,
        ...getDeviceMetadata()
      });

  _redirect = ( url ) => {
    if (!url) {
      throw new Error("Invalid redirection url");
    }
    window.location.assign( url );
  }

  _resetValidationErrors() {
    this._confirmationCodeElement.invalid = false;
    this._confirmationCodeElement.errorMessage = INVALID_CONFIRMATION_CODE_FORMAT_ERROR_MESSAGE;
  }

  _validateCheckDigit( confirmationCode ) {
    return CheckDigitValidationService.validateCrontoResponseCodeCheckDigit( confirmationCode );
  }

  _handleLogon = () => {
    this._clearTechnicalError();
    this._resetValidationErrors();
    const confirmationCodeElement = this._confirmationCodeElement;
    const confirmationCode = confirmationCodeElement.unmaskedValue;
    if (!confirmationCodeElement.validate() || !this._validateCheckDigit( confirmationCode )) {
      this._confirmationCodeElement.invalid = true;
      console.error("Validation failed!");
      return;
    }

    this._setLogonType();

    this._logonRequestInProgress = true;

    SessionService.triggerUnauthenticatedSessionCookie()
        .then(() => this._authenticateWithConfirmationCode( confirmationCode ))
        .then(
            authenticationResponse => this._handleLogonSuccess( authenticationResponse ),
            error => this._handleLogonError( error )
        );
  }

  _setLogonType() {
    setCookie(COOKIE_KEY.LOGON_TYPE, 'scanner');
  }

  _handleLogonSuccess = ({ data: { accessTokens: [{ accessToken }]}}) =>
    logout() // clear anonymous session to prevent using it for TATA call
        .then(() => this._createTransferOtp( accessToken ))
        .then(({ data: { otp }}) => this._logonToMegabank( otp ))
        .then(({ data: { url }}) => this._redirect( url ))
        .catch(error => this.handleError(BmgFeatScannerLogonScanCode.is, error));

  _handleLogonError(error) {
    this._logonRequestInProgress = false;
    const status = error?.response?.status;

    if (status === 400) {
      this._handleInvalidConfirmationCodeError(error);
    } else if (status === 403) {
      this._handleMissingEffectiveAgreementError();
    } else {
      this.handleError(BmgFeatScannerLogonScanCode.is, error);
    }
  }

  _handleInvalidConfirmationCodeError() {
      this._setErrorConfirmationCode(INVALID_CONFIRMATION_CODE_ERROR_MESSAGE);
  }

  _handleMissingEffectiveAgreementError() {
    this._setErrorConfirmationCode(EXCEEDED_CONFIRMATION_CODE_ATTEMPTS_ERROR_MESSAGE);
    this._deactivateScanner();
  }

  _setErrorConfirmationCode(errorMessage) {
    this._confirmationCodeElement.invalid = true;
    this._confirmationCodeElement.errorMessage = errorMessage;
  }

  _deactivateScanner() {
    ScannerService.deactivateScanner({
      username: this.userId
    });
  }

  _renderCrontoImage()  {
    return html`
      <bmg-feat-scanner-cronto-image
          cronto-type="${ CrontoType.AUTHENTICATION_STANDALONE }"
          authentication-context="${ JSON.stringify(LOGON_AUTHENTICATION_CONTEXT) }"
          user-id="${ this.userId }"
      ></bmg-feat-scanner-cronto-image>
    `;
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
      <div class="headline">Log on with your scanner</div>
      <ul class="info-bullets">
        <li>Scan the color code with your scanner.</li>
        <li>Enter the code in the field below.</li>
      </ul>

      ${ this._canCallCronto ? this._renderCrontoImage() : this._renderCrontoImageSpinner() }

      <bmg-form id="bmgForm" name="form">
        <div class="form-container">
          <amwa-input
              id="inputUserId"
              name="userId"
              label="User ID"
              disabled
              value="${ this.userId }"
          ></amwa-input>
          <amwa-masked-input
              id="inputConfirmationCode"
              name="confirmationCode"
              label="Confirmation code"
              required
              autofocus
              minLength="9"
              maxLength="9"
              mask="0 000 000"
              ?disabled="${ this._crontoImageExpired }"
              .errorMessage="${ INVALID_CONFIRMATION_CODE_FORMAT_ERROR_MESSAGE }"
          ></amwa-masked-input>
        </div>
        <bmg-button class="button" flat inverted @click="${ this._handleBack }">Back</bmg-button>
        <bmg-button class="button" .disabled="${ this._crontoImageExpired  || this._logonRequestInProgress }" @click="${ this._handleLogon }">Log on</bmg-button>
        <bmg-button class="right" flat inverted @click=${ this._openHelpDialog }>Need help?</bmg-button>
        <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ logonHelpDialogModel }></bmg-feat-scanner-help-dialog>
      </bmg-form>
    `;
  }

}
