import { css, unsafeCSS } from 'lit';
import { bmgHeadlineText, bmgWhite60, fontRegular, fontSizeMd, fontSizeMl } from '@bmg-web/bmg-styles';
import { bmgBlack87, bmgBlue, bmgMurrey } from '@bmg-web/bmg-styles/color';

export const amwaApplicationStyle = css`

  .headline {
    ${unsafeCSS(bmgHeadlineText)}
    margin-bottom: 12px;
  }

  bmg-card {
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    width: 470px;
    box-shadow: rgba(0, 0, 0, 0.14) 0 2px 2px 0, rgba(0, 0, 0, 0.12) 0 1px 5px 0, rgba(0, 0, 0, 0.2) 0 3px 1px -2px;
    ${unsafeCSS(fontSizeMl)}
    ${unsafeCSS(fontRegular)}
    color: ${unsafeCSS(bmgBlack87)};
  }

  .chevron-link {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .chevron-link a {
    margin: 4px 2px;
    ${unsafeCSS(fontSizeMd)}
    ${unsafeCSS(fontRegular)}
    color: ${unsafeCSS(bmgBlue)};
  }

  .chevron-icon {
    fill: ${unsafeCSS(bmgMurrey)};
  }
  
  .info-bullets {
    ${unsafeCSS(fontSizeMd)}
    margin-top: 5px;
    padding-left: 22px;
  }

  .info-bullets > li {
    list-style-type: disc;
    padding-left: 2px;
  }

  .info-bullets > li::marker {
    font-size: 0.8em;
  }
  
  .right {
    float: right;
  }

  .cronto-code {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
  }

  .cronto-code > img {
    width: 100%;
  }

  .cronto-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 190px;
    min-height: 190px;
  }
    
  .spinner-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      opacity: 0.5;
      background-color: ${unsafeCSS(bmgWhite60)};
      z-index: 999;
    }

  #bmg-spinner {
      position: fixed;
      width: 100px;
      height: 100px;
    }

`;
