/* eslint-disable max-classes-per-file */
import { css } from "lit";

import { IngFeatAuthentication } from "@ing-web/authentication";

export class AmwaHiddenFeatAuthentication extends IngFeatAuthentication {
  static get styles() {
    return css`
      :host {
        display: none;
      }
    `;
  }
}

customElements.define('amwa-hidden-feat-authentication', AmwaHiddenFeatAuthentication);