import { css, html, LitElement } from 'lit';
import { ScopedElementsMixin } from '@open-wc/scoped-elements';
import '@bmg-web/bmg-card';
import { AmwaBackToLoginLink } from '../components/amwa-back-to-login-link';
import { AmwaScannerActivationLink } from '../components/amwa-scanner-activation-link';
import { amwaApplicationStyle } from '../amwa-application-style';
import { BmgFeatScannerLogon } from "../bmg-feat-scanner/logon/BmgFeatScannerLogon";
import { initializeXsrfToken } from "../ajax/api-gateway-ajax";
import { BmgFeatScannerTechnicalError } from "../bmg-feat-scanner/components/error/BmgFeatScannerTechnicalError";

class ScannerLogonPage extends ScopedElementsMixin(LitElement) {

  static get scopedElements() {
    return {
      [ AmwaBackToLoginLink.is ]: AmwaBackToLoginLink,
      [ AmwaScannerActivationLink.is ]: AmwaScannerActivationLink,
      [ BmgFeatScannerLogon.is ]: BmgFeatScannerLogon,
      [ BmgFeatScannerTechnicalError.is ]: BmgFeatScannerTechnicalError,
    };
  }

  static get properties() {
    return {};
  }

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    await initializeXsrfToken();
  }

  static get styles() {
    return [
      amwaApplicationStyle,
      css`

        .logon-scanner-card {
          width: 426px;
        }

        .card-body {
          margin-bottom: 10px;
        }

      `,
    ];
  }

  render() {
    return html`
        <div class="logon-scanner-card">
            <bmg-feat-scanner-technical-error></bmg-feat-scanner-technical-error>
        </div>
      <bmg-card class="logon-scanner-card">
        <div class="card-body">
          <bmg-feat-scanner-logon
              aria-live="polite"
          ></bmg-feat-scanner-logon>
        </div>
        <amwa-scanner-activation-link></amwa-scanner-activation-link>
        <amwa-back-to-login-link></amwa-back-to-login-link>
      </bmg-card>
    `;
  }
}

customElements.define('scanner-logon-page', ScannerLogonPage);
