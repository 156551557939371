import { styles } from './BmgFeatScannerHelpDialog.style.js';
import { html } from "lit";
import { BmgFeatScannerElementBase } from "../base/BmgFeatScannerElementBase";
import "@bmg-web/bmg-dialog/bmg-dialog.js";

export class BmgFeatScannerHelpDialog extends BmgFeatScannerElementBase {
    static get is() {
        return 'bmg-feat-scanner-help-dialog';
    }

    static get styles() {
        return styles;
    }

    static get properties() {
        return {
            model: {
                type: Object,
                attribute: 'model'
            },
            _selectedSituation: {
                type: String,
                attribute: false,
                state: true
            },
        };
    }

    constructor() {
        super();
        this._selectedSituation = "";
    }

    get _situationToResolutionMap() {
        return this.model.items;
    }

    get _isSituationsView() {
        return !this._situationToResolutionMap.has(this._selectedSituation);
    }

    get _dialogRootElement() {
        return this.shadowRoot.querySelector("#dialogRoot");
    }

    _renderSituation(situation) {
        return html`
            <div class="situation-option" @click=${() => {
                this._selectedSituation = situation
            }}>
                ${situation}
                <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
            </div>
        `
    }

    _renderSituationsOverview() {
        return html`
            <div class="situation-list">
                ${Array.from(this._situationToResolutionMap, ([ situation ]) => this._renderSituation(situation))}
            </div>
        `;
    }

    _renderSelectedSituationResolution() {
        return this._situationToResolutionMap.get(this._selectedSituation);
    }

    _renderContent() {
        return this._isSituationsView
            ? this._renderSituationsOverview()
            : this._renderSelectedSituationResolution();
    }

    _goBack() {
        this._selectedSituation = "";
    }

    open() {
        console.log(this._dialogRootElement)
        this._dialogRootElement.open();
    }

    render() {
        return html`
            <bmg-dialog
                id="dialogRoot"
                dialogTitle=${this._isSituationsView ? this.model.title : this._selectedSituation}
                .arrowBackIcon=${!this._isSituationsView}
                @goBack="${() => this._goBack()}"
            >
                <div slot="dialog-content">
                    ${this._renderContent()}
                </div>
            </bmg-dialog>
        `;
    }
}
