import { html } from 'lit';
import { scannerLogonStyle } from './BmgFeatScannerLogon.style.js';
import { goToError } from "../../util/navigate";
import {
  BmgFeatScannerElementBase,
  GENERIC_EXCEPTION_EVENT_ID,
  GO_BACK,
  SUCCESS
} from "../components/base/BmgFeatScannerElementBase";
import { BmgFeatScannerLogonUserId } from "./step-1-user-id/BmgFeatScannerLogonUserId";
import { BmgFeatScannerLogonScanCode } from "./step-2-scan-code/BmgFeatScannerLogonScanCode";
import { LogTraceInterceptor } from "../../ajax/log-trace-interceptor";
import { logout } from "@ing-web/token-manager";

const START_STEP = BmgFeatScannerLogonUserId.is;

export class BmgFeatScannerLogon extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-logon';
  }

  static get scopedElements() {
    return {
      [BmgFeatScannerLogonUserId.is]:   BmgFeatScannerLogonUserId,
      [BmgFeatScannerLogonScanCode.is]: BmgFeatScannerLogonScanCode
    };
  }

  static get properties() {
    return {
      _currentStep: {
        type: String,
      },
      _userId: {
        type: String,
      },
    };
  }

  static get styles() {
    return scannerLogonStyle;
  }

  constructor() {
    super();
    this._populateValuesForProperties();
  }

  _populateValuesForProperties() {
    this._currentStep = START_STEP;
  }

  connectedCallback() {
    super.connectedCallback();
    logout();
    LogTraceInterceptor.clearUsername();
    this._startEventListeners();
  }

  disconnectedCallback() {
    LogTraceInterceptor.clearUsername();
    logout();
    super.disconnectedCallback();
  }

  _startEventListeners() {

    this.addEventListener(BmgFeatScannerLogonUserId.is + SUCCESS, (event) => {
      this._userId = event.detail.userId;
      LogTraceInterceptor.setUsername( this._userId );
      this._goToStep(BmgFeatScannerLogonScanCode.is);
    });

    this.addEventListener(BmgFeatScannerLogonScanCode.is + GO_BACK, () => {
      this._goToStep(BmgFeatScannerLogonUserId.is);
    });

    this.addEventListener(GENERIC_EXCEPTION_EVENT_ID, async () => {
      await LogTraceInterceptor.pendingTraceErrorCalls();
      goToError();
    });
  }

  _goToStep(step) {
    this._currentStep = step;
    window.setTimeout(() => {
      this.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  }

  _renderSteps() {
    console.log('_renderSteps() ->', this._currentStep); // TODO: remove
    switch (this._currentStep) {

      case BmgFeatScannerLogonUserId.is:
        return html`
            <bmg-feat-scanner-logon-user-id
                    aria-live="polite"
                    user-id="${ this._userId }"
            ></bmg-feat-scanner-logon-user-id>
        `;

      case BmgFeatScannerLogonScanCode.is:
        return html`
            <bmg-feat-scanner-logon-scan-code
                    aria-live="polite"
                    user-id="${ this._userId }"
            ></bmg-feat-scanner-logon-scan-code>
        `;

      default:
        throw new Error("Unknown step definition: " + this._currentStep);
    }
  }

  render() {
    return html`
        ${ this._renderSteps() }
    `;
  }

}
