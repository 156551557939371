import { format, utcToZonedTime } from "date-fns-tz";


/**
 * Converts date object into string using the timestamp format in AMS time
 * @param {Date} dateObject
 */
export function dateToTimestamp(dateObject) {
    const amsterdamDate = utcToZonedTime(dateObject, 'Europe/Amsterdam');

    const options = {
        timeZone: 'Europe/Amsterdam',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    const timeFormat = format(amsterdamDate, 'HH:mm:ss', options);
    const dateFormat = format(amsterdamDate, 'MMMM d, y', options);

    return `${dateFormat} ${timeFormat} AMS`;
}