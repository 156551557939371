import { AccessTokenRetrieveRequest, TransferAccessTokenTypes } from "./model/transfers-access-tokens-types";
import { SSO_TP_CLIENT_ID } from "../../util/service-utils";
import { apiGatewayAjax } from "../../ajax/api-gateway-ajax";

export class TransferAccessTokenService {

  static initiateTransferToSsoService(accessTokenToTransfer) {
    const createTransferOtpRequest = {
      clientId: SSO_TP_CLIENT_ID,
      intent: 'default'
    };

    return apiGatewayAjax.post(TransferAccessTokenTypes.createTransferOtp.endpoint, createTransferOtpRequest, {
      headers : {
        'Authorization' : `Bearer ${ accessTokenToTransfer }` // Beware, that Token Manager interceptor will override this header if there's an ongoing session
      }
    });
  }

  static async getTransferAccessToken(transferOtpResponse, authenticationContext) {
    const otp = transferOtpResponse.transferAccessTokenOtp;
    const accessTokenHeaders = this.createAccessTokenHeaders();
    const accessTokenRetrieveRequest = new AccessTokenRetrieveRequest(otp, authenticationContext);

    return await apiGatewayAjax.post(TransferAccessTokenTypes.accessToken.endpoint, accessTokenRetrieveRequest, {
      headers: accessTokenHeaders
    });
  }

  //TODO: Change this when X-ING-Source-IP Header value is known
  static createAccessTokenHeaders() {
    return {
      'X-ING-Source-IP': '85.150.120.89'
    };
  }

}