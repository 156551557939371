import { css, unsafeCSS } from 'lit';
import { bmgBlue, fontRegular, fontSizeMd } from "@bmg-web/bmg-styles";

// language=CSS
export const styles = css`
    .alertError {
        display: flex;
        margin-bottom: 16px;
        justify-content: center;
    }

    .mail-link {
        ${unsafeCSS(fontSizeMd)}
        ${unsafeCSS(fontRegular)}
        color: ${unsafeCSS(bmgBlue)};
    }
    
    .current-timestamp {
        margin-bottom: 0px;    
    }
`;