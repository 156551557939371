import { LogService } from "../bmg-feat-scanner/services/log.service";


const applicableForLogging = (url) => url.match( /^https?:\/\/api\..*\/(security-means|security)\// );

export class LogTraceInterceptor {

  static _username;
  static _pendingTraceErrorCalls = [];

  static traceInterceptor = (response) => {
    const { baseURL, url } = response.config;

    if ([baseURL, url].filter(Boolean).some( applicableForLogging )) {
      const username = this._username || 'UNKNOWN';
      const context = JSON.stringify({ username });
      LogService.trace( { operation: url, context });
    }

    return response;
  };

  static errorInterceptor = (err) => {
    const { baseURL, url } = err.config;

    if ([baseURL, url].filter(Boolean).some( applicableForLogging )) {
      const traceId = err.response?.headers?.['x-ing-response-id'];
      const username = this._username || 'UNKNOWN';
      const context = JSON.stringify({ username, traceId });
      this._pendingTraceErrorCalls.push(
        LogService.traceError( { operation: url, context })
      );
    }

    return err;
  };

  static setUsername = (username) => {
    this._username = username;
  };

  static clearUsername = () => {
    this._username = undefined;
  };

  static pendingTraceErrorCalls = () => {
    const currentPendingTraceCalls = this._pendingTraceErrorCalls;
    this._pendingTraceErrorCalls = [];
    return Promise.allSettled( currentPendingTraceCalls );
  }
}