

export class CheckDigitValidationService {

  /**
   * For cronto response codes that support check-digit validation, this implementation of the
   * {@link https://en.wikipedia.org/wiki/Luhn_algorithm Luhn algorithm} can be used.
   * Simplified from the OneSpan implementation,
   * see {@link https://dev.azure.com/INGCDaaS/IngOne/_workitems/edit/3228030/ Story #3228030}
   */
  static validateCrontoResponseCodeCheckDigit(code) {
        const responseCodeArray = Array.from(code).map(Number);
        const checkDigit = responseCodeArray.pop();

        const calculatedCheckDigit =
          (11 -
            responseCodeArray.reduce((previous, current) => {
              let sumOfCurrentAndPreviousNumber = current + previous;
              if (sumOfCurrentAndPreviousNumber > 10) {
                sumOfCurrentAndPreviousNumber %= 10;
              }
              return (sumOfCurrentAndPreviousNumber * 2) % 11;
            }, 10)) %
          10;

        return calculatedCheckDigit === checkDigit;
    }

    /**
     * Validates checksum of the scanner serial number using the 'interleaved factor three' algorithm
     *
     * The "interleaved factor 3" algorithm is (source: OneSpan documentation):
     * - the index starts from zero.
     * - three times the sum of the digits on EVEN positions
     * - plus the sum of the digits on ODD positions
     * - should be 0 when calculating modulo 10
     * The modulo 10, or Luhn algorithm, is a simple checksum formula
     * for validating a set of numbers.)
     */
    // eslint-disable-next-line class-methods-use-this
    static validateScannerSerialNumberCheckDigit(serialNumber) {
        return (
            Array.from(serialNumber)
                .map((digit, index) => {
                    if (index % 2 === 0) {
                        return digit * 3;
                    }
                    return digit * 1;
                })
                .reduce((sum, digit) => sum + digit)
            % 10 === 0
        );
    }

    /**
     * Validates one-time password check digit using
     * {@link https://en.wikipedia.org/wiki/Damm_algorithm Damm checkdigit algorithm}
     * Adapted from this
     * {@link https://en.wikibooks.org/wiki/Algorithm_Implementation/Checksums/Damm_Algorithm#JavaScript reference implementation}
     */
    static validateOneTimePasswordCheckDigit(code) {
        const table = [
            [0, 3, 1, 7, 5, 9, 8, 6, 4, 2],
            [7, 0, 9, 2, 1, 5, 4, 8, 6, 3],
            [4, 2, 0, 6, 8, 7, 1, 3, 5, 9],
            [1, 7, 5, 0, 9, 8, 3, 4, 2, 6],
            [6, 1, 2, 3, 0, 4, 5, 9, 7, 8],
            [3, 6, 7, 4, 2, 0, 9, 5, 8, 1],
            [5, 8, 6, 9, 7, 2, 0, 1, 3, 4],
            [8, 9, 4, 5, 3, 6, 2, 0, 1, 7],
            [9, 4, 3, 8, 6, 1, 7, 2, 0, 5],
            [2, 5, 8, 1, 4, 3, 6, 7, 9, 0]
        ];

        const checksum = Array.from(code).map(Number)
            .reduce((sum, digit) => table[sum][digit], 0);

        return checksum === 0;
    }
}