import { css } from 'ing-web';

export const styles = css`
  .device-image-svg {
    min-width: 150px;
    min-height: 150px;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
  }

  @media (min-width: 560px) {
    .device-image-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .device-image-svg {
      max-width: 100%;
      max-height: 100%;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
    }
  }
`;
