import { css, html, LitElement, unsafeCSS } from 'lit';
import { bmgBlue, bmgWhite, fontRegular } from '@bmg-web/bmg-styles';
import { amwaApplicationStyle } from '../amwa-application-style';
import '@bmg-web/bmg-icon';

export class AmwaFooter extends LitElement {

    static get properties() {
        return {};
    }

    constructor() {
        super();
    }

    static get styles() {
        return [
            amwaApplicationStyle,
            css`

            .container {
                min-height: 80px;
                background-color: ${unsafeCSS(bmgBlue)};
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            .chevron-icon {
                fill: ${unsafeCSS(bmgWhite)};
            }

            a {
                ${unsafeCSS(fontRegular)}
                color: ${unsafeCSS(bmgWhite)};
                font-weight: 50;
                margin-right: 20px;
            }

            .ing-logo {
                margin: 0px 0px 0px 60px;
                width: 110px;
            }

            `,
        ];
    }

    render() {
        return html`
            <div class="container">
                <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                <a target="_blank" href="/bmg-access-means/v1/static/downloads/Electronic-banking-conditions_2024.pdf">Electronic banking conditions</a>
                <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                <a target="_blank" href="/bmg-access-means/v1/static/downloads/000932_general-banking-conditions-abv2017-en.pdf">General banking conditions</a>
                <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                <a target="_blank" href="/bmg-access-means/v1/static/downloads/Using_Megabank_securely_Version_2.pdf">Security</a>
                <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                <a target="_blank" href="https://www.mendesgans.com/privacy-and-cookies.aspx.html">Privacy and cookies</a>
                <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                <a target="_blank" href="https://www.ing.com/ING.com-Security.htm">Reporting vulnerabilities</a>
                <a target="_blank" href="https://www.ing.com"><img
                        src="/bmg-access-means/v1/static/image/part-of-ing-white.svg"
                        alt="Part of ING" class="ing-logo"></a>
            </div>
        `;
    }
}

customElements.define('amwa-footer', AmwaFooter);
