import { css, unsafeCSS } from 'lit';
import { bmgBlue15, bmgLabelBlue } from "@bmg-web/bmg-styles";
import { bmgMurrey } from "@bmg-web/bmg-styles/color";
// language=CSS
export const styles = css`
    a {
        color: ${unsafeCSS(bmgLabelBlue)};
        font-size: inherit;
    }
    
    .situation-list {
        display: flex;
        flex-direction: column;
    }
    
    .situation-option {
        cursor: pointer;
        display: flex;
        padding: 16px 8px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #00000052
    }

    .situation-option .chevron-icon {
        fill: #9B004C;
    }
    
    .situation-option:hover {
        background-color: ${unsafeCSS(bmgBlue15)};
    }
    
    .resolution-subject {
        color: ${unsafeCSS(bmgMurrey)};
        font-size: 14px;
    }
    
    .resolution-body {
        font-size: 14px;
        margin-bottom: 14px;
    }
`;
