import { html } from 'lit';
import { styles } from "./BmgFeatScannerMeansAgreement.style";
import {
    BmgFeatScannerElementBase,
    INVALID_SCANNER_MEANS_AGREEMENT_ERROR_CODE
} from "../../../components/base/BmgFeatScannerElementBase";
import { ScannerService } from "../../../services/scanner.service";
import { ScannerStatus } from "../../../model/cronto-type";
import { SCANNER_ALREADY_ACTIVATED_EVENT } from "../scanner-already-activated-alert/BmgFeatScannerAlreadyActivated";


export const MEANS_AGREEMENT_SUCCESS = "means-agreement-success-event";

export class BmgFeatScannerMeansAgreement extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-means-agreement';
  }

  static get scopedElements() {
    return {};
  }

  static get properties() {
    return {
      userId: {
        type: String,
        attribute: "user-id"
      }
    };
  }

  static get styles() {
    return styles;
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    ScannerService.getScannerStatus({ userName: this.userId })
        .then((response) => this._handleGetMeansSuccess(response.data))
        .catch((error) => this.handleError(BmgFeatScannerMeansAgreement.is, error))
  }


  _handleGetMeansSuccess(scannerStatus) {
    switch (scannerStatus.status) {
      case ScannerStatus.REQUESTED:
      case ScannerStatus.POTENTIAL:
        this.fireCustomEvent(MEANS_AGREEMENT_SUCCESS);
        break;
      case ScannerStatus.OFFERED:
      case ScannerStatus.COMPLETED:
        ScannerService.scannerProvision({userName: this.userId})
            .then(() => this.fireCustomEvent(MEANS_AGREEMENT_SUCCESS))
            .catch((error) => this.handleError(BmgFeatScannerMeansAgreement.is, error));
        break;
      case ScannerStatus.EFFECTIVE:
        this.fireCustomEvent(SCANNER_ALREADY_ACTIVATED_EVENT);
        break;
      default:
        const error = new Error("Invalid Scanner means agreement state");
        error.code = INVALID_SCANNER_MEANS_AGREEMENT_ERROR_CODE;
        this.handleError(BmgFeatScannerMeansAgreement.is, error);
        break;
    }
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">2. Connect your scanner</div>
        ${ this._renderSpinnerOverlay() }
    `;
  }
}

customElements.define(BmgFeatScannerMeansAgreement.is, BmgFeatScannerMeansAgreement);