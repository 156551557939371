import { css, unsafeCSS } from 'lit';
import { amwaApplicationStyle } from "../../../../amwa-application-style";
import { fontRegular, fontSizeMd, fontSizeMl } from "@bmg-web/bmg-styles";
import { bmgBlue } from "@bmg-web/bmg-styles/color";

export const styles = css`
  ${ amwaApplicationStyle }
  
  .form-container {
    margin-top: 16px;
  }

  .step-title {
    ${ unsafeCSS(fontSizeMl) }
    ${ unsafeCSS(fontRegular) }
    color: ${ unsafeCSS(bmgBlue) };
  }
  
  .link-paragraph {
    display: block;
    font-weight: bold;
  }

  .mail-link {
    ${unsafeCSS(fontSizeMd)}
    ${unsafeCSS(fontRegular)}
    color: ${unsafeCSS(bmgBlue)};
  }

  .button {
    margin-bottom: 16px;
  }
`;
