import { css } from 'lit';
// language=CSS
export const styles = css`
  :host {
    display: block;
    box-sizing: border-box;
  }

  .img-responsive {
    min-width: 160px;
    min-height: 190px;
    width: 190px;
    height: auto;
  }

  .expired {
      filter: grayscale(100%) opacity(40%);
  }

  .cronto-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 190px;
      min-height: 190px;
  }

  .centered-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
  }
`;
