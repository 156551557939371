export function goToLogonChoice() {
    externalLocation("/bmg-access-means/index.html");
}

export function goToMobileLogon() {
    externalLocation("/bmg-access-means/v1/#!/mobile");
}

export function goToScannerLogon() {
    externalLocation("/bmg-access-means/pages/logon/scanner");
}

export function goToTokenLogon() {
    externalLocation("/bmg-access-means/v1/#!/token/user-id");
}

export function goToTokenLogonForScannerActivation() {
    pushUrlParams({ step: 1 }); // Start from step 1 on browser Back
    externalLocation("/bmg-access-means/v1/#!/token/activate-scanner");
}

export function goToPasswordLogon() {
    externalLocation("/bmg-access-means/v1/#!/password/user-id");
}

export function goToScannerActivation() {
    externalLocation("/bmg-access-means/pages/activate-scanner");
}

export function goToError() {
    externalLocation("/bmg-access-means/v1/#!/error");
}

export function goToTokenLinkIbUser() {
    externalLocation("/bmg-access-means/v1/index.html#!/link/user-id");
}

export function goToMobileLinkIbUser() {
    externalLocation("/bmg-access-means/v1/#!/link-ib-user/mobile");
}

function externalLocation(path) {
    window.location.href = path;
}

// Navigates to internal location using vaading router, defined in amwa-app.js
export function navigate(url) {
    // First push the new location to the navigation history:
    window.history.pushState(null, document.title, url);
    // Then emit an event to signal Vaadin router to navigate to that URL:
    window.dispatchEvent(new PopStateEvent('popstate'));
}

export function redirectToSessionExpired() {
    const currentURL = new URL(window.location.href);
    currentURL.pathname += '/sessionExpired';
    window.location.assign(currentURL);
}

// Adds new params to url without changing the page
export const pushUrlParams = (urlParams) => {
    const url = new URL ( window.location );
    Object.entries( urlParams ).forEach(([name, value]) => url.searchParams.set(name, String(value)) );
    window.history.replaceState( null, document.title, url );
}

// Removes search params from url and returns them without changing the page
export const popUrlParams = () => {
    const urlParams = new URLSearchParams( window.location.search );
    window.history.replaceState( null, document.title, window.location.pathname );
    return urlParams;
}
