import { css, html, LitElement } from 'lit';
import '@bmg-web/bmg-ripple';
import { amwaApplicationStyle } from '../amwa-application-style';
import '@bmg-web/bmg-icon';
import { goToLogonChoice } from "../util/navigate";
import { deleteLogonTypeCookie } from "../util/cookie-service";


export class AmwaBackToLoginLink extends LitElement {
    static get is() {
        return 'amwa-back-to-login-link';
    }

    static get properties() {
        return {};
    }

    static get styles() {
        return [amwaApplicationStyle,
            css`
            `,
        ];
    }

    _backToLogon(event) {
        event.preventDefault();
        deleteLogonTypeCookie();
        goToLogonChoice();
        return false;
    }

    render() {
        return html`
            <div class="chevron-link">
                <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                <a href="#" @mousedown=${ this._backToLogon }>Back to Megabank logon</a>
            </div>
        `;
    }
}

customElements.define('amwa-back-to-login-link', AmwaBackToLoginLink);
