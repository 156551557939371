import { bmgAjax } from "@bmg-web/bmg-ajax";

import { bmgAjaxOptions } from "../../util/service-utils";

export class ScannerService {

    static requestOtp = (otpRequest) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/scanner/activation/otp/request', otpRequest , {
            ...bmgAjaxOptions()
        });

    static authenticateWithOtp = (otpAuthenticateRequest) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/scanner/activation/otp/authenticate', otpAuthenticateRequest , {
            ...bmgAjaxOptions()
        });

    static authenticateWithToken = (tokenAuthenticateRequest) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/scanner/activation/token/authenticate', tokenAuthenticateRequest , {
            ...bmgAjaxOptions()
        });

    static finishActivation = (finishActivationRequest) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/scanner/activation/finish', finishActivationRequest , {
            ...bmgAjaxOptions()
        });

    static logon = (scannerLogonRequest) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/scanner/logon', scannerLogonRequest, {
            ...bmgAjaxOptions()
        });

    static deactivateScanner = (deactivateScannerRequest) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/scanner/deactivate', deactivateScannerRequest , {
            ...bmgAjaxOptions()
        });

    static scannerProvision = (scannerProvisionRequest) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/scanner/provision', scannerProvisionRequest , {
            ...bmgAjaxOptions()
        });

    static getScannerStatus = async (scannerStatusRequest) =>
        bmgAjax.post('/bmg-access-means/v1/api/public/scanner/status', scannerStatusRequest , {
            ...bmgAjaxOptions()
        });

}
