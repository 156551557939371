import { css, html, LitElement, unsafeCSS } from 'lit';
import { bmgBlue, bmgWhite, fontRegular } from '@bmg-web/bmg-styles';
import { amwaApplicationStyle } from '../amwa-application-style';

export class AmwaHeader extends LitElement {

    static get properties() {
        return {
        };
    }

    constructor() {
        super();
    }

    static get styles() {
        return [
            amwaApplicationStyle,
            css`
              .header-container {
                height: 72px;
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: ${unsafeCSS(bmgWhite)};
              }

              .header-logo {
                display: flex;
                width: 32%;
                min-width: 303px;
                padding: 18px 32px 24px 110px;
                justify-content: flex-end;
                align-items: center;
              }

              .header-logo img {
                height: 30px;
              }

              .header-title {
                background: ${unsafeCSS(bmgBlue)};
                display: flex;
                width: 100%;
                padding: 16px 24px;
                align-items: center;
                align-self: flex-start;

                ${unsafeCSS(fontRegular)}
                color: ${unsafeCSS(bmgWhite)};
                font-size: 32px;
                font-style: normal;
                line-height: 40px;
              }

            `,
        ];
    }

    render() {
        return html`
            <div class="header-container">
                <div class="header-logo">
                    <img src="/bmg-access-means/v1/static/image/bmg-logo.svg" alt="BMG">
                </div>
                <div class="header-title">Megabank</div>
            </div>

        `;
    }
}

customElements.define('amwa-header', AmwaHeader);
