import { AppSettings } from "./app-settings";

export const AMWA_TP_CLIENT_ID = "cc9c86d6-5884-4122-bcf3-64ef42d0022c";
export const SSO_TP_CLIENT_ID = "fea5dfc7-5123-471c-be36-f149d3a2503d";

export const ANONYMOUS_AUTHENTICATION_CONTEXT = {
    authenticationContext: {
        clientId: AMWA_TP_CLIENT_ID,
        scopes: [],
    }
};

export const bmgAjaxOptions = () => ({
    xsrfCookieName: 'XSRF-TOKEN',
    withCredentials: true,
    timeout: AppSettings.bmgApiTimeout,
});

//TODO: maybe our own ajax client with defaults e.g.:
// let amwaAjaxSingleton;
// export const amwaAjaxFactory = () => amwaAjaxSingleton ??
//         (amwaAjaxSingleton =
//                 axios.create({
//                     withCredentials: true,
//                     timeout: AppSettings.bmgApiTimeout,
//                 })
//         );

export const apiGatewayAjaxOptions = () => ({
    withCredentials: true,
    timeout: AppSettings.apiGatewayTimeout,
});
