import { css, html, LitElement } from 'lit';
import { AmwaLoginChoice } from '../components/amwa-login-choice';
import '@bmg-web/bmg-card';
import { AmwaLoginLink } from '../components/amwa-login-link';
import { amwaApplicationStyle } from '../amwa-application-style';
import { goToScannerActivation } from '../util/navigate';
import { ScopedElementsMixin } from "@open-wc/scoped-elements";

class LoginChoicePage extends ScopedElementsMixin(LitElement) {

    static get scopedElements() {
      return {
        "amwa-login-choice": AmwaLoginChoice,
        "amwa-login-link": AmwaLoginLink,
      };
    }

    static get properties() {
        return {};
    }

    constructor() {
        super();
    }

    static get styles() {
        return [
            amwaApplicationStyle,
            css`
                .activate-scanner-section {
                  margin-top: 32px;
                }
            `,
        ];
    }

    render() {
        return  html`
            <amwa-login-choice></amwa-login-choice>
            <div class="activate-scanner-section">
                <bmg-card>
                    <div class="headline">New scanner?</div>
                    <amwa-login-link @click="${ goToScannerActivation }">Activate it first</amwa-login-link>
                </bmg-card>
            </div>
        `;
    }
}

customElements.define('login-choice-page', LoginChoicePage);
