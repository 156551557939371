import { css, html, LitElement } from 'lit';
import '@bmg-web/bmg-ripple';
import { amwaApplicationStyle } from '../amwa-application-style';
import '@bmg-web/bmg-icon';


export class AmwaNeedHelpLink extends LitElement {

    static get properties() {
        return {
            href: {type: String},
        };
    }

    static get styles() {
        return [amwaApplicationStyle,
            css`
            `,
        ];
    }

    render() {

        return html`
                <div class="chevron-link">
                    <bmg-icon class="chevron-icon" icon="chevron_right"></bmg-icon>
                    <a target="_blank" href="${this.href}">
                     <slot></slot>
                    </a>
                </div>

        `;
    }
}

customElements.define('amwa-need-help-link', AmwaNeedHelpLink);
