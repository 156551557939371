import { amwaApplicationStyle } from "../../../../amwa-application-style";
import { css, unsafeCSS } from 'lit';
import { bmgBlue, bmgBlue18, fontRegular, fontSizeMl } from "@bmg-web/bmg-styles";

export const styles = css`
  ${ amwaApplicationStyle }

  .form-container {
    background-color: ${unsafeCSS(bmgBlue18)};
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
  }

  .step-title {
    ${ unsafeCSS(fontSizeMl) }
    ${ unsafeCSS(fontRegular) }
    color: ${ unsafeCSS(bmgBlue) };
  }

  .button {
    margin-bottom: 16px;
  }

  .image-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;

    width: 100%;
    margin-bottom: 16px;
  }

  .img-responsive {
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 250px;
  }
  
  .notification {
    margin-bottom: 16px;
  }
  .info-bullets{
    margin-bottom: 5px;
  }
  
`;
